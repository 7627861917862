import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import DateRangePicker from "../../../../DatePickers/DateRangePicker/DateRangePicker";
import EventsAnalysisAPI from "../../../../../api/EventsAnalysisApi";
import Cookies from "js-cookie";
import ReactLoading from "react-loading";

const formatDate = (date) => {
    return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
};

export default function EventsThreshold({ tabData, setTabData, setPlotsOrder }) {
    const token = Cookies.get("Session");

    // extarcting from redux slice
    const { selectedCustomer, selectedFacility, selectedEquipment, equipmentTimezone } = useSelector(
        (state) => state.equipmentsPortfolio
    );

    // Events Threshold Tab Hooks
    const [eventsThresholdStartDate, setEventsThresholdStartDate] = useState(
        new Date(new Date().setMonth(new Date().getMonth() - 1))
    );
    const [eventsThresholdEndDate, setEventsThresholdEndDate] = useState(new Date());

    const [isEventsThresholdPlotLoading, setIsEventsThresholdPlotLoading] = useState(false);
    const [eventsThresholdAlert, setEventsThresholdAlert] = useState({
        color: "blue",
        text: "",
        show: false,
    });

    useEffect(() => {
        setEventsThresholdAlert({ ...eventsThresholdAlert, show: false });
    }, [selectedCustomer, selectedFacility, selectedEquipment]);

    useEffect(() => {
        if (selectedEquipment) {
            const equipStartDate = new Date(parseInt(selectedEquipment.equipment_start_epoch * 1000));

            // Reset Date Pickers
            const tempDate = new Date(new Date().setMonth(new Date().getMonth() - 1));

            if (equipStartDate > eventsThresholdStartDate) {
                if (equipStartDate > tempDate) setEventsThresholdStartDate(equipStartDate);
                else setEventsThresholdStartDate(tempDate);

                setEventsThresholdEndDate(new Date());
            }
        }
    }, [selectedEquipment]);

    {
        /************************************ Events Threshold Tab fuctions **************************************************************/
    }

    const onEventsThresholdStartDateChange = (date) => {
        setEventsThresholdStartDate(date);
    };

    const onEventsThresholdEndDateChange = (date) => {
        setEventsThresholdEndDate(date);
    };

    const onEventsThresholdPlotButtonClick = () => {
        if (!selectedCustomer) {
            setEventsThresholdAlert({
                color: "primary",
                show: true,
                text: "Select a Customer",
            });
        } else if (!selectedFacility) {
            setEventsThresholdAlert({
                color: "primary",
                show: true,
                text: "Select a Facility",
            });
        } else if (!selectedEquipment) {
            setEventsThresholdAlert({
                color: "primary",
                show: true,
                text: "Select an Equipment ",
            });
        } else {
            setEventsThresholdAlert({ ...eventsThresholdAlert, show: false });
            setIsEventsThresholdPlotLoading(true);
            setPlotsOrder({
                eventsCapture: 4,
                correlatingEvents: 2,
                eventsTrending: 3,
                eventsThreshold: 1,
                eventsFeaturesPlot: 5,
            });
            setTabData((prevState) => {
                return {
                    ...prevState,
                    plotData: {
                        time: [],
                        pqd_vmin: [],
                        pqd_vmax: [],
                        pqd_imin: [],
                        pqd_imax: [],
                        pq_delta_v: [],
                        pq_delta_i: [],
                        i24maxD: [],
                        i24minD: [],
                        v24maxD: [],
                        v24minD: [],
                    },
                    plotMetaData: {
                        selectedEquipment: selectedEquipment,
                        startDate: formatDate(eventsThresholdStartDate),
                        endDate: formatDate(eventsThresholdEndDate),
                    },
                    isPlotLoading: true,
                    showPlot: true,
                };
            });

            EventsAnalysisAPI.get_event_threshold_plot_data(
                token,
                selectedEquipment.value,
                formatDate(eventsThresholdStartDate),
                formatDate(eventsThresholdEndDate),
                equipmentTimezone.value
            )
                .then((response) => {
                    if (response.status === 200) {
                        console.log(response.data.content);
                        setTabData((prevState) => {
                            return {
                                ...prevState,
                                plotData: response.data.content.plot_data,
                                showPlot: true,
                                isPlotLoading: false,
                            };
                        });
                    }
                    setIsEventsThresholdPlotLoading(false);
                })
                .catch((err) => {
                    setIsEventsThresholdPlotLoading(false);
                    setTabData((prevState) => {
                        return {
                            ...prevState,
                            isPlotLoading: false,
                        };
                    });
                    console.log(err);
                });
        }
    };

    return (
        <div>
            <Row>
                <Col className="col-7 event-data">
                    <h2 className="margin-top-10">Events Threshold</h2>
                    <label>Select Date Range:</label>
                    <br></br>
                    <DateRangePicker
                        startDate={eventsThresholdStartDate}
                        endDate={eventsThresholdEndDate}
                        onStartDateChange={onEventsThresholdStartDateChange}
                        onEndDateChange={onEventsThresholdEndDateChange}
                    />
                </Col>
            </Row>
            <Row>
                <Col className="col-2">
                    <br></br>
                    {isEventsThresholdPlotLoading ? (
                        <ReactLoading type="bars" height={50} width={50} color="#007bff" />
                    ) : (
                        <Button variant="primary" onClick={onEventsThresholdPlotButtonClick} className="plot_button">
                            Plot
                        </Button>
                    )}
                </Col>
                <Col className="col-5 eventsAlert">
                    <br />
                    <Alert
                        variant={eventsThresholdAlert.color}
                        show={eventsThresholdAlert.show}
                        onClose={() =>
                            setEventsThresholdAlert({
                                ...eventsThresholdAlert,
                                show: false,
                            })
                        }
                        dismissible
                    >
                        {eventsThresholdAlert.text}
                    </Alert>
                </Col>
            </Row>
        </div>
    );
}
