import authAxios from '../axios/authAxios';

class DailyReportsAPI {
	static getDailyReport(accessToken, reportName, reportDate) {
		return authAxios.get('/v2/daily_reports/' + reportName + '/', {
			params: {
				utc_date: `${reportDate}`,
			},
			headers: { Authorization: `Bearer ${accessToken}` },
		});
	}
}

export default DailyReportsAPI;