import React from 'react';
import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import { equipmentsPortfolioActions } from '../../../../store/equipmentsPortfolioSlice/equipmentsPortfolioSlice';

const SelectCustomer = () => {
	const { customerList, selectedCustomer } = useSelector((state) => state.equipmentsPortfolio);
	const dispatch = useDispatch();

	const onSelectCustomer = (customer) => {
		dispatch(equipmentsPortfolioActions.setSelectedCustomer(customer));
	};

	return (
		<React.Fragment>
			<Select options={customerList} value={selectedCustomer} onChange={(value) => onSelectCustomer(value)} />
		</React.Fragment>
	);
};

export default SelectCustomer;
