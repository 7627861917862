import DatePicker from 'react-datepicker';
import { getMonth, getYear } from 'date-fns';
import range from 'lodash/range';

const SingleDatePickerSimple = (props) => {
	const { startDate, onDateChange, isDisabled } = props;

	const years = range(getYear(2015), getYear(new Date()) + 1, 1);
	const months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];
	return (
		<DatePicker
			selected={startDate}
			maxDate={new Date()}
			todayButton='Today'
			disabled={isDisabled}
			renderCustomHeader={({
				date,
				changeYear,
				changeMonth,
				decreaseMonth,
				increaseMonth,
				prevMonthButtonDisabled,
				nextMonthButtonDisabled,
			}) => (
				<div
					style={{
						margin: 10,
						display: 'flex',
						justifyContent: 'center',
					}}>
					<button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
						{'<'}
					</button>
					<select value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)}>
						{years.map((option) => (
							<option key={option} value={option}>
								{option}
							</option>
						))}
					</select>

					<select
						value={months[getMonth(date)]}
						onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
						{months.map((option) => (
							<option key={option} value={option}>
								{option}
							</option>
						))}
					</select>

					<button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
						{'>'}
					</button>
				</div>
			)}
			onChange={(date) => {
				onDateChange(date);
			}}
			dateFormat='MMM d, yyyy'
		/>
	);
};

export default SingleDatePickerSimple;
