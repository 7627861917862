const formatDate = (date) => {
	if (!date) {
	  return '---';
	} else {
	  const dateObj = new Date(date);
	  const formattedDate =
		dateObj.getUTCFullYear() +
		'-' +
		('0' + (dateObj.getUTCMonth() + 1)).slice(-2) +
		'-' +
		('0' + dateObj.getUTCDate()).slice(-2) +
		' ' +
		('0' + dateObj.getUTCHours()).slice(-2) +
		':' +
		('0' + dateObj.getUTCMinutes()).slice(-2) +
		':' +
		('0' + dateObj.getUTCSeconds()).slice(-2) +
		' UTC';
	  return formattedDate;
	}
  };

class AlertsParser {
	static onlyColumns() {
		const columns = [
			{ dataField: 'date', text: 'Time', sort: true },
			{ dataField: 'subject', text: 'Alert', sort: true },
			{ dataField: 'last_alert_send', text: 'Most Recent Sent', sort: true },
		];
		return { rows: [], columns: columns };
	}

	static onlyColumnsReload() {
		const columns = [
			{ dataField: 'date', text: 'Time', sort: true },
			{ dataField: 'subject', text: 'Alert', sort: true },
			{ dataField: 'last_alert_send', text: 'Most Recent Sent', sort: true },

		];
		return {
			rows: [ ],
			columns: columns,
		};
	}

	static activeAlertsTable(activeAlerts) {
		const columns = [
			{ dataField: 'date', text: 'Time', sort: true },
			{ dataField: 'subject', text: 'Alert', sort: true },
			{ dataField: 'last_alert_sent', text: 'Most Recent Sent', sort: true },

		];

		const rows = [...activeAlerts];
		for (const key in rows) {
			if (rows[key].content != null) {
				rows[key].alert_name = rows[key].content.alert_name;
				rows[key].body = rows[key].content.body;
				rows[key].subject = rows[key].content.subject;
				rows[key].node_sn = rows[key].content.node_sn;
				rows[key].body_s3_key = rows[key].content.body_s3_key;
				rows[key].attachments = rows[key].content.attachments;
				rows[key].node_sn_list = rows[key].content.node_sn_list;
				rows[key].date = formatDate(rows[key].time); //new Date(rows[key].time).toUTCString().replace('GMT', 'UTC')
				rows[key].last_alert_sent = formatDate(rows[key].last_alert_send_details.time) + " - " +( rows[key].last_alert_send_details.notified_by ? rows[key].last_alert_send_details.notified_by : "" );
				rows[key].deployment_issue = rows[key].content.deployment_issue
				delete rows[key].content;
			}
		}

		return { rows: rows, columns: columns };
	}

	static archiveAlertsTable(activeAlerts) {
		const columns = [
			{ dataField: 'date', text: 'Time', sort: true, width:'10px' },
			{ dataField: 'subject', text: 'Alert', sort: true },
			{ dataField: 'last_alert_sent', text: 'Most Recent Sent', sort: true },

		];
		const rows = [...activeAlerts];
		for (const key in rows) {
			if (rows[key].content != null) {
				rows[key].alert_name = rows[key].content.alert_name;
				rows[key].body = rows[key].content.body;
				rows[key].subject = rows[key].content.subject;
				rows[key].node_sn = rows[key].content.node_sn;
				rows[key].body_s3_key = rows[key].content.body_s3_key;
				rows[key].attachments = rows[key].content.attachments;
				rows[key].node_sn_list = rows[key].content.node_sn_list;
				rows[key].date = formatDate(rows[key].time); //new Date(rows[key].time).toUTCString().replace('GMT', 'UTC')
				rows[key].last_alert_sent = formatDate(rows[key].last_alert_send_details.time) + " - " +( rows[key].last_alert_send_details.notified_by ? rows[key].last_alert_send_details.notified_by : "" );
				rows[key].deployment_issue = rows[key].content.deployment_issue
				delete rows[key].content;
			}
		}

		return { rows: rows, columns: columns };
	}

	static sentAlertsTable(activeAlerts) {
		const columns = [
			{ dataField: 'date', text: 'Time', sort: true },
			{ dataField: 'subject', text: 'Alert', sort: true },
			{ dataField: 'alert_sent_details', text: 'Sent By', sort: true },

		];
		const rows = [...activeAlerts];
		for (const key in rows) {
			if (rows[key].content != null) {
				rows[key].alert_name = rows[key].content.alert_name;
				rows[key].body = rows[key].content.body;
				rows[key].subject = rows[key].content.subject;
				rows[key].node_sn = rows[key].content.node_sn;
				rows[key].body_s3_key = rows[key].content.body_s3_key;
				rows[key].attachments = rows[key].content.attachments;
				rows[key].node_sn_list = rows[key].content.node_sn_list;
				rows[key].date = formatDate(rows[key].time); //new Date(rows[key].time).toUTCString().replace('GMT', 'UTC')
				rows[key].alert_sent_details = formatDate(rows[key].alert_send_details.time) + " - " +( rows[key].alert_send_details.notified_by ? rows[key].alert_send_details.notified_by : "" );
				rows[key].deployment_issue = rows[key].content.deployment_issue

				delete rows[key].content;
			}
		}

		return { rows: rows, columns: columns };
	}

	static filterHarmonics(alerts, filter) {
		const hideRows = []
		alerts.map((row, i) => {
			if(row.alert_name.includes("Harmonic Amplitude") != filter){
				hideRows.push(row.id)
			}
		})
		return hideRows
	}
}

class AlertsTrendingPlotParser {
	static getEmptyResponse() {
		const plotResponse = {
			plotTitle: '',
		};
		return plotResponse;
	}
	static getPlotResponse(plotMetaData) {
		const plotResponse = {
			plotTitle:
				plotMetaData.selectedEquipment.plot_title_node_label +
				` (${plotMetaData.selectedEquipment.np_voltage}V, ${plotMetaData.selectedEquipment.np_current}A, ${plotMetaData.selectedEquipment.np_rpm}RPM, ${plotMetaData.selectedEquipment.np_hp}HP)`+
				'<br>Date: ' +
				plotMetaData.startDate +
				' to ' +
				plotMetaData.endDate,
		};
		return plotResponse;
	}
}

export { AlertsParser, AlertsTrendingPlotParser };
