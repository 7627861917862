import authAxios from '../axios/authAxios';

class HarmonicMappingAPI {
	static getHarmonicMapping(accessToken) {
		return authAxios.get('/v2/harmonic-signatures/', {
			headers: { Authorization: `Bearer ${accessToken}` },
		});
	}
}

export default HarmonicMappingAPI;