import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useSelector } from 'react-redux';
import './EventsActiveBanner.css';

const EventsActiveBanner = () => {
	const selectedEquipment = useSelector((state) => state.equipmentsPortfolio.selectedEquipment);
	return (
		<React.Fragment>
			{selectedEquipment ? (
				<>
					{selectedEquipment.product_type === "Node" && selectedEquipment.events_active === false ? (
						<>
							<br></br>
							<Row className='justify-content-md-center'>
								<Col className='col-11 events-active-box'>
									<text>Selected Equipment has events_active = 0</text>
								</Col>
							</Row>
						</>
					) : null}
				</>
			) : null}
		</React.Fragment>
	);
};

export default EventsActiveBanner;
