import authAxios from "../axios/authAxios";

class EventsAnalysisAPI {
    static get_events_data(
        accessToken,
        location_node_id,
        startDate,
        endDate,
        timezone,
        np_current,
        outliers,
        product_type,
        v_type
    ) {
        if (typeof np_current != Number) {
            np_current = 0.1;
        }
        return authAxios.get("/v1/event_analysis/get_events_list/", {
            params: {
                location_node_id: `${location_node_id}`,
                start_date: `${startDate}`,
                end_date: `${endDate}`,
                timezone: `${timezone}`,
                np_current: `${np_current}`,
                outliers: `${outliers}`,
                product_type: `${product_type}`,
                v_type: `${v_type}`,
            },
            headers: { Authorization: `Bearer ${accessToken}` },
        });
    }

    static event_capture_plot_data(
        accessToken,
        selectedEsa_value,
        location_node_id,
        analysis_type,
        product_type,
        v_type,
        moving_average
    ) {
        let param = {
            event_key: `${selectedEsa_value}`,
            location_node_id: `${location_node_id}`,
            product_type: `${product_type}`,
            v_type: v_type,
            moving_average: moving_average,
        };

        if (analysis_type !== "None") {
            param["analysis_type"] = `${analysis_type}`;
        }

        return authAxios.get("/v1/event_analysis/event_plot/", {
            params: param,
            headers: { Authorization: `Bearer ${accessToken}` },
        });
    }

    static get_correlating_events_list(accessToken, selectedFacility, timestamp, tolerance, timezone) {
        return authAxios.get("/v1/event_analysis/correlate_event_timestamp/", {
            params: {
                facility_id: `${selectedFacility}`,
                timestamp: `${timestamp}`,
                tolerance: `${tolerance}`,
                timezone: `${timezone}`,
            },
            headers: { Authorization: `Bearer ${accessToken}` },
        });
    }

    static get_events_trending_data(accessToken, location_node_id, startDate, endDate, timezone) {
        return authAxios.get("/v1/event_analysis/events_trending_data/", {
            params: {
                location_node_id: `${location_node_id}`,
                start_date: `${startDate}`,
                end_date: `${endDate}`,
                timezone: `${timezone}`,
            },
            headers: { Authorization: `Bearer ${accessToken}` },
        });
    }

    static get_event_threshold_plot_data(accessToken, location_node_id, startDate, endDate, timezone) {
        return authAxios.get("/v1/event_analysis/events_threshold_data/", {
            params: {
                location_node_id: `${location_node_id}`,
                start_date: `${startDate}`,
                end_date: `${endDate}`,
                timezone: `${timezone}`,
            },
            headers: { Authorization: `Bearer ${accessToken}` },
        });
    }

    static get_events_features_data(accessToken, location_node_id, selected_feature, startDate, endDate, timezone) {
        return authAxios.get("/v1/event_analysis/event_features_data/", {
            params: {
                selected_feature: `${selected_feature}`,
                location_node_id: `${location_node_id}`,
                start_date: `${startDate}`,
                end_date: `${endDate}`,
                timezone: `${timezone}`,
            },
            headers: { Authorization: `Bearer ${accessToken}` },
        });
    }

    static getSELAlarmsTrip(accessToken, location_node_id, startDate, endDate, timezone) {
        return authAxios.get("/v1/event_analysis/sel_alarms_trips/", {
            params: {
                location_node_id: `${location_node_id}`,
                start_date: `${startDate}`,
                end_date: `${endDate}`,
                timezone: `${timezone}`,
            },
            headers: { Authorization: `Bearer ${accessToken}` },
        });
    }
}

export default EventsAnalysisAPI;
