import React, { useState, useEffect, Suspense, lazy } from "react";
import { useSelector } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import ReactLoading from "react-loading";
import Alert from "react-bootstrap/Alert";
import DateRangePicker from "../DatePickers/DateRangePicker/DateRangePicker";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import Form from "react-bootstrap/Form";

import LeftFilterBox from "../LeftFilterBox/LeftFilterBox";
import AlertsAPI from "../../api/Alerts";
import { AlertsParser } from "../../parser/Alerts";

import Cookies from "js-cookie";
import "../../css/Alerts.css";
import { response } from "msw";

const { SearchBar, ClearSearchButton } = Search;

const AlertsTrendingPlot = lazy(() => import("../plots/AlertsTrendingPlot"));

const formatDate = (date) => {
    return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
};

const Alerts = () => {
    const token = Cookies.get("Session");

    // extarcting from redux slice
    const { selectedCustomer, selectedFacility, selectedEquipment, equipmentTimezone, customerList } = useSelector(
        (state) => state.equipmentsPortfolio
    );

    // Refresh Hook
    const [reloadData, setReloadData] = useState(1);
    const [isActiveLoading, setIsActiveLoading] = useState(false);
    const [isArchiveLoading, setIsArchiveLoading] = useState(false);
    const [isHarmonicLoading, setIsHarmonicLoading] = useState(false);
    const [isSentLoading, setIsSentLoading] = useState(false);

    // Active Tab Hooks
    const [activeAlertsTable, setActiveAlertsTable] = useState(AlertsParser.onlyColumns());
    const [activeAlert, setActiveAlert] = useState({ color: "blue", text: "", show: false });
    const [activeAlertModal, setActiveAlertModal] = useState({ show: false });

    const [selectedActiveAlert, setSelectedActiveAlert] = useState({
        subject: "",
        body: "",
    });
    const [activeHtmlContent, setActiveHtmlContent] = useState({});
    const [archiveHtmlContent, setArchiveHtmlContent] = useState({});

    // Archive Tab Hooks
    const [archiveAlertsTable, setArchiveAlertsTable] = useState(AlertsParser.onlyColumns());
    const [archiveAlert, setArchiveAlert] = useState({ color: "blue", text: "", show: false });
    const [displayHarmonicArchives, setDisplayHarmonicArchives] = useState(false);
    const [hiddenArchives, setHiddenArchives] = useState([]);

    //Harmonic Tab Hooks
    const [harmonicAlertsTable, setHarmonicAlertsTable] = useState(AlertsParser.onlyColumns());
    const [harmonicAlert, setHarmonicAlert] = useState({ color: "blue", show: false });

    // Sent alerts tab
    const [sentAlertsTable, setSentAlertsTable] = useState(AlertsParser.onlyColumns());
    const [sentAlert, setSentAlert] = useState({ color: "blue", text: "", show: false });
    const [sentHtmlContent, setSentHtmlContent] = useState({});

    // Alerts Trending Hooks
    const [alertsTrendingStartDate, setAlertsTrendingStartDate] = useState(
        new Date(new Date().setMonth(new Date().getMonth() - 1))
    );
    const [alertsTrendingEndDate, setAlertsTrendingEndDate] = useState(new Date());

    const [alertsTrendingAlert, setAlertsTrendingAlert] = useState({ color: "blue", text: "", show: false });
    const [alertsTrendingPlotData, setAlertsTrendingPlotData] = useState();
    const [alertsTrendingPlotMeataData, setAlertsTrendingPlotMetaData] = useState();
    const [alertsTrendingPlotLoading, setAlertsTrendingPlotLoading] = useState(false);
    const [showAlertsTrendingPlot, setShowAlertsTrendingPlot] = useState(false);

    const [falsePositiveAlert, setFalsePositiveAlert] = useState({ color: "blue", text: "", show: false });

    {
        /************************************ Active Alerts Tab fuctions **************************************************************/
    }
    useEffect(() => {
        if (customerList.length != 0) {
            setActiveAlertsTable(AlertsParser.onlyColumnsReload());
            setArchiveAlertsTable(AlertsParser.onlyColumnsReload());
            setSentAlertsTable(AlertsParser.onlyColumnsReload());
            setHarmonicAlertsTable(AlertsParser.onlyColumnsReload());

            setIsActiveLoading(true);
            setIsArchiveLoading(true);
            setIsHarmonicLoading(true);
            setIsSentLoading(true);

            // Api Call to get active alerts
            AlertsAPI.getActiveAlets(token)
                .then((response) => {
                    if (response.status === 200) {
                        setActiveAlertsTable(AlertsParser.activeAlertsTable(response.data.content));
                    }
                    setIsActiveLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });

            // Api Call to get archive alerts
            AlertsAPI.getArchiveAlerts(token)
                .then((response) => {
                    if (response.status === 200) {
                        setArchiveAlertsTable(AlertsParser.archiveAlertsTable(response.data.content));
                        setHiddenArchives(AlertsParser.filterHarmonics(response.data.content, displayHarmonicArchives));
                    }
                    setIsArchiveLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });

            //Api Call to get harmonic alerts
            AlertsAPI.getHarmonicAlerts(token)
                .then((response) => {
                    if (response.status === 200) {
                        setHarmonicAlertsTable(AlertsParser.archiveAlertsTable(response.data.content));
                    }
                    setIsHarmonicLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });

            // Api Call to get sent alerts
            AlertsAPI.getSentAlerts(token)
                .then((response) => {
                    if (response.status === 200) {
                        setSentAlertsTable(AlertsParser.sentAlertsTable(response.data.content));
                    }
                    setIsSentLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [reloadData, customerList]);

    const activeAlertsExpandRow = {
        renderer: (row) => (
            <>
                <Row>
                    <Col className="col-8">
                        <p className="bodyText">{row.body}</p>
                        {activeHtmlContent[row.id] === undefined ? null : (
                            <div dangerouslySetInnerHTML={{ __html: activeHtmlContent[row.id] }} />
                        )}
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                <Button className="btn_alert" value={row.id} onClick={onSendButtonClick}>
                                    Send
                                </Button>
                            </Col>
                            <Col>
                                <Button className="btn_alert" value={row.id} onClick={onArchiveButtonClick}>
                                    Archive
                                </Button>
                            </Col>
                            <Col>
                                <Button className="btn_alert" value={row.id} onClick={onFalsePositiveClick}>
                                    False Positive
                                </Button>
                            </Col>
                        </Row>
                        <br />
                        <Alert variant="warning" show={Date.now() / 1000 - row["file_epoch"] > 86400}>
                            Alert is generated from file more than 24 hours ago
                        </Alert>
                        <Alert variant="warning" show={row["deployment_issue"] == true}>
                            Deployment Issue
                        </Alert>
                    </Col>
                </Row>
            </>
        ),

        onExpand: (row, isExpand, rowIndex, e) => {
            if (isExpand) {
                AlertsAPI.getAlertsContent(token, row.body_s3_key, JSON.stringify(row.attachments)).then((response) => {
                    if (response.status === 200) {
                        setActiveHtmlContent({ ...activeHtmlContent, [row.id]: response.data });
                    }
                });
            }
        },
    };

    const onSendButtonClick = (val) => {
        const id = val.target.value;
        setActiveAlert({ ...activeAlert, show: false });

        setSelectedActiveAlert(
            activeAlertsTable.rows.filter((value) => {
                return value.id == id;
            })[0]
        );

        setActiveAlertModal({ show: true });
    };

    const onYesButtonClick = () => {
        AlertsAPI.patchNotifyCustomer(token, selectedActiveAlert.id).then((response) => {
            if (response.status === 200) {
                const rows = activeAlertsTable.rows.filter((value) => {
                    if (value.id == selectedActiveAlert.id) {
                        const tempSentRows = [...sentAlertsTable.rows];
                        tempSentRows.push(value);
                        setSentAlertsTable({ ...sentAlertsTable, rows: tempSentRows });
                        setSentAlert({ color: "secondary", text: value.subject + "Sent To Customer", show: true });
                    }
                    return value.id != selectedActiveAlert.id;
                });

                setActiveAlertsTable({ ...activeAlertsTable, rows: rows });
                setActiveAlert({
                    color: "success",
                    text: selectedActiveAlert.subject + " Sent To Customer",
                    show: true,
                });
            }
        });
        setActiveAlertModal({ show: false });
        AlertsAPI.patchEventTag(token, selectedActiveAlert.id, "positive positive");
    };

    const onArchiveButtonClick = (val) => {
        const id = val.target.value;

        AlertsAPI.patchActiveToArchive(token, val.target.value).then((response) => {
            if (response.status === 200) {
                const row = activeAlertsTable.rows.filter((value) => {
                    // Move to archive list
                    if (value.id == id) {
                        const tempArchiveRows = [...archiveAlertsTable.rows];
                        tempArchiveRows.push(value);
                        setArchiveAlertsTable({ ...archiveAlertsTable, rows: tempArchiveRows });
                        setActiveAlert({ color: "secondary", text: value.subject + " Moved To Archive", show: true });
                    }
                    return value.id != id;
                });

                setActiveAlertsTable({ ...activeAlertsTable, rows: row });
            }
        });
    };

    const onFalsePositiveClick = (val) => {
        const id = val.target.value;

        AlertsAPI.patchActiveToArchive(token, val.target.value, true).then((response) => {
            if (response.status === 200) {
                const row = activeAlertsTable.rows.filter((value) => {
                    // Move to archive list
                    if (value.id == id) {
                        value.false_alert = true;
                        const tempArchiveRows = [...archiveAlertsTable.rows];
                        tempArchiveRows.push(value);
                        setArchiveAlertsTable({ ...archiveAlertsTable, rows: tempArchiveRows });
                        setActiveAlert({ color: "secondary", text: value.subject + " Moved To Archive", show: true });
                    }
                    return value.id != id;
                });

                setActiveAlertsTable({ ...activeAlertsTable, rows: row });
            }
        });
        AlertsAPI.patchEventTag(token, id, "false positive");
    };

    {
        /************************************ Archive Tab fuctions **************************************************************/
    }

    useEffect(() => {
        setHiddenArchives(AlertsParser.filterHarmonics(archiveAlertsTable.rows, displayHarmonicArchives));
    }, [displayHarmonicArchives]);

    // Archive Alerts Functions
    const archiveAlertsExpandRow = {
        renderer: (row) => (
            <>
                <Alert variant={"warning"} show={row["false_alert"] == true}>
                    Alert Marked as False Positive
                </Alert>
                <Row>
                    <Col className="col-8">
                        <p className="bodyText">{row.body}</p>
                        {archiveHtmlContent[row.id] === undefined ? null : (
                            <div dangerouslySetInnerHTML={{ __html: archiveHtmlContent[row.id] }} />
                        )}
                    </Col>
                    <Col>
                        <Button className="btn_alert" value={row.id} onClick={onMoveToActiveButtonClick}>
                            Move to Active
                        </Button>
                    </Col>
                </Row>
                <br></br>
                <Alert variant="warning" show={row["deployment_issue"] == true}>
                    Deployment Issue
                </Alert>
            </>
        ),

        onExpand: (row, isExpand, rowIndex, e) => {
            if (isExpand) {
                AlertsAPI.getAlertsContent(token, row.body_s3_key, JSON.stringify(row.attachments)).then((response) => {
                    if (response.status === 200) {
                        setArchiveHtmlContent({ ...archiveHtmlContent, [row.id]: response.data });
                    }
                });
            }
        },
    };

    const onMoveToActiveButtonClick = (val) => {
        const id = val.target.value;

        AlertsAPI.patchArchiveToActive(token, val.target.value).then((response) => {
            if (response.status === 200) {
                const row = archiveAlertsTable.rows.filter((value) => {
                    // Move to archive list
                    if (value.id == id) {
                        value.false_alert = false;
                        const tempActiveRows = [...activeAlertsTable.rows];
                        tempActiveRows.push(value);
                        setActiveAlertsTable({ ...activeAlertsTable, rows: tempActiveRows });
                        setArchiveAlert({ color: "secondary", text: value.subject + " Moved To Active", show: true });
                    }
                    return value.id != id;
                });

                setArchiveAlertsTable({ ...activeAlertsTable, rows: row });
            }
        });
        AlertsAPI.patchEventTag(token, val.target.value, "");
    };

    {
        /************************************ Harmonics Tab fuctions **************************************************************/
    }

    // Archive Alerts Functions
    const harmonicAlertsExpandRow = {
        renderer: (row) => (
            <>
                <Row>
                    <Col className="col-8">
                        <p className="bodyText">{row.body}</p>
                        {archiveHtmlContent[row.id] === undefined ? null : (
                            <div dangerouslySetInnerHTML={{ __html: archiveHtmlContent[row.id] }} />
                        )}
                    </Col>
                    <Col>
                        <Button
                            className="btn_alert"
                            value={row.id}
                            onClick={(val) => {
                                const id = val.target.value;

                                AlertsAPI.patchArchiveToActive(token, val.target.value).then((response) => {
                                    if (response.status === 200) {
                                        const row = harmonicAlertsTable.rows.filter((value) => {
                                            // Move to archive list
                                            if (value.id == id) {
                                                value.false_alert = false;
                                                const tempActiveRows = [...activeAlertsTable.rows];
                                                tempActiveRows.push(value);
                                                setActiveAlertsTable({ ...activeAlertsTable, rows: tempActiveRows });
                                                setHarmonicAlert({
                                                    color: "secondary",
                                                    text: value.subject + " Moved To Active",
                                                    show: true,
                                                });
                                            }
                                            return value.id != id;
                                        });

                                        setHarmonicAlertsTable({ ...harmonicAlertsTable, rows: row });
                                    }
                                });
                                AlertsAPI.patchEventTag(token, val.target.value, "");
                            }}
                        >
                            Move to Active
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            className="btn_alert"
                            value={row.id}
                            onClick={(val) => {
                                const id = val.target.value;

                                AlertsAPI.patchActiveToArchive(token, val.target.value).then((response) => {
                                    if (response.status === 200) {
                                        const row = harmonicAlertsTable.rows.filter((value) => {
                                            // Move to archive list
                                            if (value.id == id) {
                                                const tempArchiveRows = [...archiveAlertsTable.rows];
                                                tempArchiveRows.push(value);
                                                setArchiveAlertsTable({ ...archiveAlertsTable, rows: tempArchiveRows });
                                                setHarmonicAlert({
                                                    color: "secondary",
                                                    text: value.subject + " Moved To Archive",
                                                    show: true,
                                                });
                                            }
                                            return value.id != id;
                                        });

                                        setHarmonicAlertsTable({ ...harmonicAlertsTable, rows: row });
                                    }
                                });
                            }}
                        >
                            Archive
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            className="btn_alert"
                            value={row.id}
                            onClick={(val) => {
                                const id = val.target.value;

                                AlertsAPI.patchActiveToArchive(token, val.target.value, true).then((response) => {
                                    if (response.status === 200) {
                                        const rows = harmonicAlertsTable.rows.filter((value) => {
                                            // Move to archive list
                                            if (value.id == id) {
                                                value.false_alert = true;
                                                const tempArchiveRows = [...archiveAlertsTable.rows];
                                                tempArchiveRows.push(value);
                                                setArchiveAlertsTable({ ...archiveAlertsTable, rows: tempArchiveRows });
                                                setHarmonicAlert({
                                                    color: "secondary",
                                                    text: value.subject + " Moved To Archive",
                                                    show: true,
                                                });
                                            }
                                            return value.id != id;
                                        });

                                        setHarmonicAlertsTable({ ...harmonicAlertsTable, rows: rows });
                                    }
                                });
                                AlertsAPI.patchEventTag(token, id, "false positive");
                            }}
                        >
                            False Positive
                        </Button>
                    </Col>
                </Row>
                <br></br>
                <Alert variant="warning" show={row["deployment_issue"] == true}>
                    Deployment Issue
                </Alert>
            </>
        ),

        onExpand: (row, isExpand, rowIndex, e) => {
            if (isExpand) {
                AlertsAPI.getAlertsContent(token, row.body_s3_key, JSON.stringify(row.attachments)).then((response) => {
                    if (response.status === 200) {
                        setArchiveHtmlContent({ ...archiveHtmlContent, [row.id]: response.data });
                    }
                });
            }
        },
    };

    {
        /************************************ Sent Alerts Tab fuctions **************************************************************/
    }

    // Sent Alerts Functions
    const sentAlertsExpandRow = {
        renderer: (row) => (
            <div className={`sendAlert-Expanded-${row["id"]}`}>
                <Alert variant={"warning"} show={row["false_alert"]}>
                    Alert Marked as False Positive
                </Alert>
                <Row>
                    <Col className="col-8">
                        <p className="bodyText">{row.body}</p>
                        {sentHtmlContent[row.id] === undefined ? null : (
                            <div dangerouslySetInnerHTML={{ __html: sentHtmlContent[row.id] }} />
                        )}
                    </Col>
                    <Col>
                        <Button
                            id="send_false_alert"
                            className="btn_alert"
                            disabled={row["false_alert"]}
                            value={row.id}
                            onClick={(value) => {
                                Promise.all([
                                    AlertsAPI.patchEventTag(token, value.target.value, "false positive"),
                                    AlertsAPI.patchNotifyCustomer(token, row.id, true),
                                ]).then(([response1, response2]) => {
                                    if (response1.status == 200 && response2.status == 200) {
                                        setSentAlert({
                                            color: "primary",
                                            text: row.subject + " Marked as False Positive",
                                            show: true,
                                        });
                                        row.false_alert = true;
                                        setSentAlertsTable({ ...sentAlertsTable });
                                    } else {
                                        setSentAlert({
                                            color: "danger",
                                            text: "Error Marking as False Positive",
                                            show: true,
                                        });
                                    }
                                    return;
                                });
                            }}
                        >
                            False Positive
                        </Button>
                    </Col>
                </Row>
                <br></br>
                <Alert variant="warning" show={row["deployment_issue"] == true}>
                    Deployment Issue
                </Alert>
            </div>
        ),

        onExpand: (row, isExpand, rowIndex, e) => {
            if (isExpand) {
                AlertsAPI.getAlertsContent(token, row.body_s3_key, JSON.stringify(row.attachments)).then((response) => {
                    if (response.status === 200) {
                        setSentHtmlContent({ ...sentHtmlContent, [row.id]: response.data });
                    }
                });
            }
        },
    };

    {
        /************************************ Alerts Trending Tab fuctions **************************************************************/
    }
    // Alerts trending functions
    const onAlertsTrendingStartDateChange = (date) => {
        setAlertsTrendingStartDate(date);
    };

    const onAlertsTrendingEndDateChange = (date) => {
        setAlertsTrendingEndDate(date);
    };

    const onAlertsTrendingPlotButtonClick = () => {
        setAlertsTrendingAlert({
            color: "primary",
            show: false,
            text: "",
        });

        if (!selectedCustomer) {
            setAlertsTrendingAlert({
                color: "primary",
                show: true,
                text: "Select a Customer",
            });
        } else if (!selectedFacility) {
            setAlertsTrendingAlert({
                color: "primary",
                show: true,
                text: "Select a Facility",
            });
        } else if (!selectedEquipment) {
            setAlertsTrendingAlert({
                color: "primary",
                show: true,
                text: "Select an Equipment ",
            });
        } else {
            setAlertsTrendingAlert({ ...alertsTrendingAlert, show: false });
            setAlertsTrendingPlotLoading(true);

            setAlertsTrendingPlotMetaData({
                selectedEquipment: selectedEquipment,
                facilityName: selectedFacility.label,
                equipmentName: selectedEquipment.label,
                startDate: formatDate(alertsTrendingStartDate),
                endDate: formatDate(alertsTrendingEndDate),
            });
            setAlertsTrendingPlotData({
                upper_subplot: [],
                lower_subplot: [],
                alerts_trending: {},
            });
            setShowAlertsTrendingPlot(true);

            AlertsAPI.getAlertsTrendingPlot(
                token,
                selectedEquipment.location_node_id,
                formatDate(alertsTrendingStartDate),
                formatDate(alertsTrendingEndDate),
                equipmentTimezone.value
            )
                .then((response) => {
                    if (response.status === 204) {
                        setAlertsTrendingAlert({
                            color: "primary",
                            show: true,
                            text: "No Alerts Found ",
                        });
                        setAlertsTrendingPlotLoading(false);
                    }
                    setAlertsTrendingPlotLoading(false);
                    setAlertsTrendingPlotData(response.data.content.plot_data);
                    setShowAlertsTrendingPlot(true);
                })
                .catch((error) => {
                    setAlertsTrendingPlotLoading(false);
                    console.log(error);
                });
        }
    };

    const onAlertsTrendingPlotCloseButtonClick = () => {
        setShowAlertsTrendingPlot(false);
    };

    return (
        <div>
            <Card>
                <Card.Body>
                    <Row className="mt-3">
                        {/************************************************* Sub Menu Tabs **********************************************************************/}
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Tabs variant="pills">
                                        {/************************************************* Active Tab **********************************************************************/}
                                        <Tab eventKey="Active" title="Active">
                                            <Row className="mt-4">
                                                <Col>
                                                    <Card>
                                                        <Card.Header>
                                                            <h4>Active Alerts</h4>
                                                        </Card.Header>

                                                        <Card.Body>
                                                            <Row className="mt-3">
                                                                <Col className="alertsTable first-col-width last-col-width">
                                                                    {activeAlertsTable.columns.length ? (
                                                                        <ToolkitProvider
                                                                            keyField="id"
                                                                            data={activeAlertsTable.rows}
                                                                            columns={activeAlertsTable.columns}
                                                                            search
                                                                            bootstrap4
                                                                        >
                                                                            {(props) => (
                                                                                <div>
                                                                                    <Row>
                                                                                        <Col className="col-2">
                                                                                            <SearchBar
                                                                                                {...props.searchProps}
                                                                                            />
                                                                                        </Col>

                                                                                        <Col className="col-1">
                                                                                            <ClearSearchButton
                                                                                                className="btn-primary btn_alert"
                                                                                                {...props.searchProps}
                                                                                            />
                                                                                        </Col>
                                                                                        <Col className="col-6">
                                                                                            <Alert
                                                                                                variant={
                                                                                                    activeAlert.color
                                                                                                }
                                                                                                show={activeAlert.show}
                                                                                                onClose={() =>
                                                                                                    setActiveAlert({
                                                                                                        ...activeAlert,
                                                                                                        show: false,
                                                                                                    })
                                                                                                }
                                                                                                dismissible
                                                                                            >
                                                                                                {activeAlert.text}
                                                                                            </Alert>
                                                                                        </Col>
                                                                                        <Col className="mt-2 mb-2 col-1 offset-2">
                                                                                            {isActiveLoading ? (
                                                                                                <ReactLoading
                                                                                                    type="bars"
                                                                                                    height={50}
                                                                                                    width={50}
                                                                                                    color="#007bff"
                                                                                                />
                                                                                            ) : (
                                                                                                <Button
                                                                                                    className="btn_alert"
                                                                                                    onClick={() =>
                                                                                                        setReloadData(
                                                                                                            reloadData +
                                                                                                                1
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    Reload
                                                                                                </Button>
                                                                                            )}
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <div className="eventSearchBox_ctn"></div>

                                                                                    <BootstrapTable
                                                                                        className="active-alerts-table"
                                                                                        {...props.baseProps}
                                                                                        noDataIndication="No Alerts Found"
                                                                                        expandRow={
                                                                                            activeAlertsExpandRow
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </ToolkitProvider>
                                                                    ) : null}
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col>
                                                                    <Modal
                                                                        show={activeAlertModal.show}
                                                                        size="lg"
                                                                        centered
                                                                        onHide={() => {
                                                                            setActiveAlertModal({ show: false });
                                                                        }}
                                                                    >
                                                                        <Modal.Header>
                                                                            <h4>
                                                                                Are You Sure To Send Following Alert To
                                                                                The Customer
                                                                            </h4>
                                                                        </Modal.Header>
                                                                        <Modal.Body>
                                                                            <label>{selectedActiveAlert.subject}</label>
                                                                            <p className="bodyText">
                                                                                {" "}
                                                                                {selectedActiveAlert.body}
                                                                            </p>
                                                                        </Modal.Body>
                                                                        <Modal.Footer>
                                                                            <Button onClick={onYesButtonClick}>
                                                                                Yes
                                                                            </Button>
                                                                            <Button
                                                                                onClick={() =>
                                                                                    setActiveAlertModal({ show: false })
                                                                                }
                                                                            >
                                                                                No
                                                                            </Button>
                                                                        </Modal.Footer>
                                                                    </Modal>
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Tab>
                                        {/************************************************* Archive Tab **********************************************************************/}

                                        <Tab eventKey="Archive" title="Archive">
                                            <Row className="mt-4">
                                                <Col>
                                                    <Card>
                                                        <Card.Header>
                                                            <Row>
                                                                <Col className="col-2">
                                                                    <h4>Archive Alerts</h4>
                                                                </Col>
                                                                <Col>
                                                                    <Form.Check
                                                                        onChange={(event) => {
                                                                            setDisplayHarmonicArchives(
                                                                                event.target.checked
                                                                            );
                                                                        }}
                                                                        type="checkbox"
                                                                        label="Display Harmonic Alerts"
                                                                        checked={displayHarmonicArchives}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Card.Header>

                                                        <Card.Body>
                                                            <Row className="mt-3">
                                                                <Col className="alertsTable first-col-width last-col-width">
                                                                    {archiveAlertsTable.columns.length ? (
                                                                        <ToolkitProvider
                                                                            keyField="id"
                                                                            data={archiveAlertsTable.rows}
                                                                            columns={archiveAlertsTable.columns}
                                                                            hiddenRows={hiddenArchives}
                                                                            search
                                                                            bootstrap4
                                                                        >
                                                                            {(props) => (
                                                                                <div>
                                                                                    <Row>
                                                                                        <Col className="col-2">
                                                                                            <SearchBar
                                                                                                {...props.searchProps}
                                                                                            />
                                                                                        </Col>

                                                                                        <Col className="col-1">
                                                                                            <ClearSearchButton
                                                                                                className="btn-primary btn_alert"
                                                                                                {...props.searchProps}
                                                                                            />
                                                                                        </Col>
                                                                                        <Col className="col-6">
                                                                                            <Alert
                                                                                                variant={
                                                                                                    archiveAlert.color
                                                                                                }
                                                                                                show={archiveAlert.show}
                                                                                                onClose={() =>
                                                                                                    setArchiveAlert({
                                                                                                        ...archiveAlert,
                                                                                                        show: false,
                                                                                                    })
                                                                                                }
                                                                                                dismissible
                                                                                            >
                                                                                                {archiveAlert.text}
                                                                                            </Alert>
                                                                                        </Col>
                                                                                        <Col className="mt-2 mb-2 col-1 offset-2">
                                                                                            {isArchiveLoading ? (
                                                                                                <ReactLoading
                                                                                                    type="bars"
                                                                                                    height={50}
                                                                                                    width={50}
                                                                                                    color="#007bff"
                                                                                                />
                                                                                            ) : (
                                                                                                <Button
                                                                                                    className="btn_alert"
                                                                                                    onClick={() =>
                                                                                                        setReloadData(
                                                                                                            reloadData +
                                                                                                                1
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    Reload
                                                                                                </Button>
                                                                                            )}
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <div className="eventSearchBox_ctn"></div>

                                                                                    <BootstrapTable
                                                                                        {...props.baseProps}
                                                                                        noDataIndication="No Alerts Found"
                                                                                        expandRow={
                                                                                            archiveAlertsExpandRow
                                                                                        }
                                                                                        hiddenRows={hiddenArchives}
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </ToolkitProvider>
                                                                    ) : null}
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col>
                                                                    <Modal
                                                                        show={activeAlertModal.show}
                                                                        size="lg"
                                                                        centered
                                                                        onHide={() => {
                                                                            setActiveAlertModal({ show: false });
                                                                        }}
                                                                    >
                                                                        <Modal.Header>
                                                                            <h4>
                                                                                Are You Sure To Send Following Alert To
                                                                                The Customer
                                                                            </h4>
                                                                        </Modal.Header>
                                                                        <Modal.Body>
                                                                            <label>{selectedActiveAlert.subject}</label>
                                                                            <p className="bodyText">
                                                                                {" "}
                                                                                {selectedActiveAlert.body}
                                                                            </p>
                                                                        </Modal.Body>
                                                                        <Modal.Footer>
                                                                            <Button
                                                                                className="btn_alert"
                                                                                onClick={onYesButtonClick}
                                                                            >
                                                                                Yes
                                                                            </Button>
                                                                            {/* Added for  spacing betwwnt buttons */}
                                                                            <Row>
                                                                                <Col></Col>
                                                                            </Row>
                                                                            <Button
                                                                                className="btn_alert"
                                                                                onClick={() =>
                                                                                    setActiveAlertModal({ show: false })
                                                                                }
                                                                            >
                                                                                No
                                                                            </Button>
                                                                        </Modal.Footer>
                                                                    </Modal>
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Tab>
                                        {/************************************************* Harmonics Tab **********************************************************************/}

                                        <Tab eventKey="Harmonics" title="Harmonics">
                                            <Row className="mt-4">
                                                <Col>
                                                    <Card>
                                                        <Card.Header>
                                                            <h4>Harmonic Alerts</h4>
                                                        </Card.Header>

                                                        <Card.Body>
                                                            <Row className="mt-3">
                                                                <Col className="alertsTable first-col-width last-col-width">
                                                                    {harmonicAlertsTable.columns.length ? (
                                                                        <ToolkitProvider
                                                                            keyField="id"
                                                                            data={harmonicAlertsTable.rows}
                                                                            columns={harmonicAlertsTable.columns}
                                                                            search
                                                                            bootstrap4
                                                                        >
                                                                            {(props) => (
                                                                                <div>
                                                                                    <Row>
                                                                                        <Col className="col-2">
                                                                                            <SearchBar
                                                                                                {...props.searchProps}
                                                                                            />
                                                                                        </Col>

                                                                                        <Col className="col-1">
                                                                                            <ClearSearchButton
                                                                                                className="btn-primary btn_alert"
                                                                                                {...props.searchProps}
                                                                                            />
                                                                                        </Col>
                                                                                        <Col className="col-6">
                                                                                            <Alert
                                                                                                variant={
                                                                                                    harmonicAlert.color
                                                                                                }
                                                                                                show={
                                                                                                    harmonicAlert.show
                                                                                                }
                                                                                                onClose={() =>
                                                                                                    setHarmonicAlert({
                                                                                                        ...harmonicAlert,
                                                                                                        show: false,
                                                                                                    })
                                                                                                }
                                                                                                dismissible
                                                                                            >
                                                                                                {harmonicAlert.text}
                                                                                            </Alert>
                                                                                        </Col>
                                                                                        <Col className="mt-2 mb-2 col-1 offset-2">
                                                                                            {isHarmonicLoading ? (
                                                                                                <ReactLoading
                                                                                                    type="bars"
                                                                                                    height={50}
                                                                                                    width={50}
                                                                                                    color="#007bff"
                                                                                                />
                                                                                            ) : (
                                                                                                <Button
                                                                                                    className="btn_alert"
                                                                                                    onClick={() =>
                                                                                                        setReloadData(
                                                                                                            reloadData +
                                                                                                                1
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    Reload
                                                                                                </Button>
                                                                                            )}
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <div className="eventSearchBox_ctn"></div>

                                                                                    <BootstrapTable
                                                                                        {...props.baseProps}
                                                                                        noDataIndication="No Alerts Found"
                                                                                        expandRow={
                                                                                            harmonicAlertsExpandRow
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </ToolkitProvider>
                                                                    ) : null}
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col>
                                                                    <Modal
                                                                        show={activeAlertModal.show}
                                                                        size="lg"
                                                                        centered
                                                                        onHide={() => {
                                                                            setActiveAlertModal({ show: false });
                                                                        }}
                                                                    >
                                                                        <Modal.Header>
                                                                            <h4>
                                                                                Are You Sure To Send Following Alert To
                                                                                The Customer
                                                                            </h4>
                                                                        </Modal.Header>
                                                                        <Modal.Body>
                                                                            <label>{selectedActiveAlert.subject}</label>
                                                                            <p className="bodyText">
                                                                                {" "}
                                                                                {selectedActiveAlert.body}
                                                                            </p>
                                                                        </Modal.Body>
                                                                        <Modal.Footer>
                                                                            <Button
                                                                                className="btn_alert"
                                                                                onClick={onYesButtonClick}
                                                                            >
                                                                                Yes
                                                                            </Button>
                                                                            {/* Added for  spacing betwwnt buttons */}
                                                                            <Row>
                                                                                <Col></Col>
                                                                            </Row>
                                                                            <Button
                                                                                className="btn_alert"
                                                                                onClick={() =>
                                                                                    setActiveAlertModal({ show: false })
                                                                                }
                                                                            >
                                                                                No
                                                                            </Button>
                                                                        </Modal.Footer>
                                                                    </Modal>
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Tab>
                                        {/************************************************* Sent Alerts Tab **********************************************************************/}

                                        <Tab eventKey="Sent" title="Sent">
                                            <Row className="mt-4">
                                                <Col>
                                                    <Card>
                                                        <Card.Header>
                                                            <h4>Sent Alerts</h4>
                                                        </Card.Header>

                                                        <Card.Body>
                                                            <Row className="mt-3">
                                                                <Col className="alertsTable first-col-width last-col-width">
                                                                    {sentAlertsTable.columns.length ? (
                                                                        <ToolkitProvider
                                                                            keyField="id"
                                                                            data={sentAlertsTable.rows}
                                                                            columns={sentAlertsTable.columns}
                                                                            search
                                                                            bootstrap4
                                                                        >
                                                                            {(props) => (
                                                                                <div>
                                                                                    <Row>
                                                                                        <Col className="col-2">
                                                                                            <SearchBar
                                                                                                {...props.searchProps}
                                                                                            />
                                                                                        </Col>

                                                                                        <Col className="col-1">
                                                                                            <ClearSearchButton
                                                                                                className="btn-primary btn_alert"
                                                                                                {...props.searchProps}
                                                                                            />
                                                                                        </Col>
                                                                                        <Col className="col-6">
                                                                                            <Alert
                                                                                                variant={
                                                                                                    sentAlert.color
                                                                                                }
                                                                                                show={sentAlert.show}
                                                                                                onClose={() =>
                                                                                                    setSentAlert({
                                                                                                        ...sentAlert,
                                                                                                        show: false,
                                                                                                    })
                                                                                                }
                                                                                                dismissible={true}
                                                                                            >
                                                                                                {sentAlert.text}
                                                                                            </Alert>
                                                                                        </Col>
                                                                                        <Col className="mt-2 mb-2 col-1 offset-2">
                                                                                            {isSentLoading ? (
                                                                                                <ReactLoading
                                                                                                    type="bars"
                                                                                                    height={50}
                                                                                                    width={50}
                                                                                                    color="#007bff"
                                                                                                />
                                                                                            ) : (
                                                                                                <Button
                                                                                                    className="btn_alert"
                                                                                                    onClick={() =>
                                                                                                        setReloadData(
                                                                                                            reloadData +
                                                                                                                1
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    Reload
                                                                                                </Button>
                                                                                            )}
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <div className="eventSearchBox_ctn"></div>

                                                                                    <BootstrapTable
                                                                                        {...props.baseProps}
                                                                                        noDataIndication="No Alerts Found"
                                                                                        expandRow={sentAlertsExpandRow}
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </ToolkitProvider>
                                                                    ) : null}
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col>
                                                                    <Modal
                                                                        show={activeAlertModal.show}
                                                                        size="lg"
                                                                        centered
                                                                        onHide={() => {
                                                                            setActiveAlertModal({ show: false });
                                                                        }}
                                                                    >
                                                                        <Modal.Header>
                                                                            <h4>
                                                                                Are You Sure To Send Following Alert To
                                                                                The Customer
                                                                            </h4>
                                                                        </Modal.Header>
                                                                        <Modal.Body>
                                                                            <label>{selectedActiveAlert.subject}</label>
                                                                            <p className="bodyText">
                                                                                {" "}
                                                                                {selectedActiveAlert.body}
                                                                            </p>
                                                                        </Modal.Body>
                                                                        <Modal.Footer>
                                                                            <Button
                                                                                className="btn_alert"
                                                                                onClick={onYesButtonClick}
                                                                            >
                                                                                Yes
                                                                            </Button>
                                                                            {/* Added for  spacing betwwnt buttons */}
                                                                            <Row>
                                                                                <Col></Col>
                                                                            </Row>
                                                                            <Button
                                                                                className="btn_alert"
                                                                                onClick={() =>
                                                                                    setActiveAlertModal({ show: false })
                                                                                }
                                                                            >
                                                                                No
                                                                            </Button>
                                                                        </Modal.Footer>
                                                                    </Modal>
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Tab>
                                        <Tab eventKey="Alerts Trending" title="Alerts Trending">
                                            <Card>
                                                <Card.Body>
                                                    <Row className="mt-3">
                                                        {/************************************ Facility and Node Selection Column **************************************************************/}
                                                        <Col className="col-4">
                                                            <LeftFilterBox />
                                                        </Col>
                                                        <Col className="col-8">
                                                            <Card>
                                                                <Card.Body>
                                                                    <Tabs variant="pills">
                                                                        <Tab
                                                                            eventKey="trending"
                                                                            title="Alerts Trending"
                                                                        >
                                                                            <Row>
                                                                                <Col>
                                                                                    <h2 className="margin-top-10">
                                                                                        Alerts Trending
                                                                                    </h2>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row className="mt-1">
                                                                                <Col className="col-7 event-data">
                                                                                    <label>Select Date Range:</label>
                                                                                    <br></br>
                                                                                    <DateRangePicker
                                                                                        startDate={
                                                                                            alertsTrendingStartDate
                                                                                        }
                                                                                        endDate={alertsTrendingEndDate}
                                                                                        onStartDateChange={
                                                                                            onAlertsTrendingStartDateChange
                                                                                        }
                                                                                        onEndDateChange={
                                                                                            onAlertsTrendingEndDateChange
                                                                                        }
                                                                                    />
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col className="col-2">
                                                                                    <br></br>
                                                                                    {alertsTrendingPlotLoading ? (
                                                                                        <ReactLoading
                                                                                            type="bars"
                                                                                            height={50}
                                                                                            width={50}
                                                                                            color="#007bff"
                                                                                        />
                                                                                    ) : (
                                                                                        <Button
                                                                                            variant="primary"
                                                                                            onClick={
                                                                                                onAlertsTrendingPlotButtonClick
                                                                                            }
                                                                                            className="plot_button"
                                                                                        >
                                                                                            Plot
                                                                                        </Button>
                                                                                    )}
                                                                                </Col>
                                                                                <Col className="col-4 eventsAlert">
                                                                                    <br />
                                                                                    <Alert
                                                                                        variant={
                                                                                            alertsTrendingAlert.color
                                                                                        }
                                                                                        show={alertsTrendingAlert.show}
                                                                                        onClose={() =>
                                                                                            setAlertsTrendingAlert({
                                                                                                ...alertsTrendingAlert,
                                                                                                show: false,
                                                                                            })
                                                                                        }
                                                                                        dismissible
                                                                                    >
                                                                                        {alertsTrendingAlert.text}
                                                                                    </Alert>
                                                                                </Col>
                                                                            </Row>
                                                                        </Tab>
                                                                    </Tabs>
                                                                </Card.Body>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                            <br></br>
                                            <Suspense fallback={<div>Loading..</div>}>
                                                {showAlertsTrendingPlot ? (
                                                    <AlertsTrendingPlot
                                                        plotData={alertsTrendingPlotData}
                                                        plotMetaData={alertsTrendingPlotMeataData}
                                                        showPlot={showAlertsTrendingPlot}
                                                        onPlotCloseButtonClick={onAlertsTrendingPlotCloseButtonClick}
                                                        isPlotLoading={alertsTrendingPlotLoading}
                                                    />
                                                ) : null}

                                                <br></br>
                                            </Suspense>
                                        </Tab>
                                    </Tabs>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <br></br>
        </div>
    );
};

export default Alerts;
