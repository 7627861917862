import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { Redirect } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import AuthAPI from '../../api/Auth';

export default function Login(props) {
	const [isAuth, setIsAuth] = useState(false);
	const saved_token = Cookies.get('Session');

	useEffect(() => {
		if (props.location.search) {
			AuthAPI.getAuth(props.location.search.replace('?jwt=', ''))
				.then((token) => {
					Cookies.set('Session', token.data.content, {
						path: '/',
						expires: new Date(jwt_decode(token.data.content).exp * 1000),
					});
					props.setToken(token.data.content);
					setIsAuth(true);
				})
				.catch((err) => {
					window.location = process.env.REACT_APP_PORTAL_URL  + '/#/logout';
				});
			AuthAPI.getPortalToken(props.location.search.replace('?jwt=', '')).then((response) => {
				Cookies.set('portalSession', response.data.data.sessionToken, {
					path: '/',
					expires: new Date(new Date().setSeconds(response.data.data.sessionDuration)),
				});
			})
		} else if (saved_token) {
			AuthAPI.isLogin(saved_token)
				.then((response) => {
					setIsAuth(true);
				})
				.catch((err) => {
					window.location = process.env.REACT_APP_PORTAL_URL  + '/#/logout';
				});
		} else {
			window.location = process.env.REACT_APP_PORTAL_URL  + '/#/logout';
		}
	}, []);

	if (isAuth) {
		return <Redirect to='/dash' />;
	}

	return <div>Loading...</div>;
}
