import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import WaveformAnalysisAPI from "../../../../../api/WaveformApi";
import SingleDatePicker from "../../../../DatePickers/SingleDatePicker/SingleDatePicker";
import Dropdown from "react-dropdown";
import Cookies from "js-cookie";
import ReactLoading from "react-loading";

export default function DeploymentWaveform({ tabData, setTabData, setPlotsOrder }) {
    const token = Cookies.get("Session");

    // extarcting from redux slice
    const { selectedCustomer, selectedFacility, selectedEquipment, equipmentTimezone, showSELMenu } = useSelector(
        (state) => state.equipmentsPortfolio
    );
    const selectedVoltageType = useSelector((state) => state.voltageType.selectedVoltageType);

    const [esaList, setEsaList] = useState([]);
    const [selectedEsa, setSelectedEsa] = useState();
    const [plotDuration, setPlotDuration] = useState(2);
    const [alert, setAlert] = useState({
        text: "",
        color: "",
        show: "",
    });
    const [isPlotDurationValid, setIsPlotDurationValid] = useState(false);

    const [movingAvgLength, setMovingAvgLength] = useState(1);
    const [isMovingAvgLengthInvalid, setIsMovingAvgLengthInvalid] = useState(false);

    const [displayLLCurrent, setDisplayLLCurrent] = useState(false);
    {
        /************************************ Waveforms Tab fuctions **************************************************************/
    }

    const getESAlist = () => {
        setAlert({
            text: "No ESA Found",
            color: "primary",
            show: false,
        });

        WaveformAnalysisAPI.getDeploymentWaveform(token, selectedEquipment.location_node_id, equipmentTimezone.value)
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.content == null) {
                        const text = response.data.info;
                        setAlert({
                            text: text,
                            color: "primary",
                            show: true,
                        });
                    } else {
                        setEsaList(response.data.content.list_data);
                    }
                } else if (response.status === 204) {
                    setAlert({
                        text: "No ESA Found",
                        color: "primary",
                        show: true,
                    });
                }
            })
            .catch((error) => {
                setAlert({
                    text: "No ESA Found",
                    color: "primary",
                    show: true,
                });
            });
    };

    useEffect(() => {
        // Clear Esa Dropdown
        setEsaList([]);
        setSelectedEsa();
    }, [selectedCustomer, selectedFacility]);

    useEffect(() => {
        if (selectedEquipment) {
            console.log("test");
            setAlert({ ...alert, show: false });
            setEsaList([]);
            setSelectedEsa();

            // Api call for Esa list
            getESAlist();
        }
    }, [selectedEquipment, equipmentTimezone]);

    useEffect(() => {
        if (tabData.showPlot) {
            setTabData((prevState) => {
                return {
                    ...prevState,
                    plotMetaData: { ...prevState.plotMetaData, selectedVoltageType: selectedVoltageType },
                };
            });
        }
    }, [selectedVoltageType]);

    useEffect(() => {
        setTabData((prevState) => {
            return {
                ...prevState,
                plotMetaData: { ...prevState.plotMetaData, displayLLCurrent: displayLLCurrent },
            };
        });
    }, [displayLLCurrent]);

    const onMovingAvgLengthChange = (newLength) => {
        //Add time delay for set / replot
        const length = parseFloat(newLength);
        setMovingAvgLength(length);
        if (length >= 1 && length <= 1024) {
            setIsMovingAvgLengthInvalid(false);
        } else {
            setIsMovingAvgLengthInvalid(true);
        }
    };

    const onPlotButtonClick = async () => {
        if (!selectedCustomer) {
            setAlert({
                text: "Select a Customer",
                color: "primary",
                show: true,
            });
        } else if (!selectedFacility) {
            setAlert({
                text: "Select a Facility",
                color: "primary",
                show: true,
            });
        } else if (!selectedEquipment) {
            setAlert({
                text: "Select an Equipment",
                color: "primary",
                show: true,
            });
        } else if (!selectedEsa) {
            setAlert({
                text: "Select a ESA File",
                color: "primary",
                show: true,
            });
        } else if (!(plotDuration > 0 && plotDuration <= 40)) {
            setAlert({
                text: "Enter number of seconds between 0 - 40",
                color: "primary",
                show: true,
            });
        } else if (isMovingAvgLengthInvalid) {
            setAlert({
                color: "primary",
                text: "Please select value between 1 and 1024",
                show: true,
            });
        } else {
            setAlert({ ...alert, show: false });
            setPlotsOrder({
                deployment: 1,
                waveform: 2,
                rtWaveform: 3,
            });
            setTabData((prevState) => {
                return {
                    ...prevState,
                    plotData: {
                        time: [],
                        current: {
                            Ia: [],
                            Ib: [],
                            Ic: [],
                        },
                        voltage_ln: {
                            Va: [],
                            Vb: [],
                            Vc: [],
                        },
                    },
                    showPlot: true,
                    isPlotLoading: true,
                    plotMetaData: {
                        selectedEquipment: selectedEquipment,
                        selectedESA: selectedEsa,
                        selectedVoltageType: selectedVoltageType,
                        movingAvgLength: movingAvgLength,
                        displayLLCurrent: displayLLCurrent,
                    },
                };
            });

            WaveformAnalysisAPI.getEsaPlotData(
                token,
                selectedEsa.value,
                selectedEquipment.location_node_id,
                40,
                selectedEquipment.product_type
            )
                .then((response) => {
                    if (response.status === 204) {
                        setTabData((prevState) => {
                            return {
                                ...prevState,
                                isPlotLoading: false,
                            };
                        });
                        setAlert({
                            text: "No content for selected ESA file",
                            color: "primary",
                            show: true,
                        });
                    } else {
                        if ("status_code" in response.data && response.data.status_code == 404) {
                            setTabData((prevState) => {
                                return {
                                    ...prevState,
                                    isPlotLoading: false,
                                    showPlot: false,
                                };
                            });
                            setAlert({
                                text: "No content for selected ESA file",
                                color: "primary",
                                show: true,
                            });
                        } else {
                            setTabData((prevState) => {
                                return {
                                    ...prevState,
                                    plotData: response.data.content.plot_data,
                                    showPlot: true,
                                    isPlotLoading: false,
                                };
                            });
                        }
                    }
                })
                .catch((error) => {
                    setTabData((prevState) => {
                        return {
                            ...prevState,
                            isPlotLoading: false,
                        };
                    });
                    setAlert({
                        text: "Select a ESA File",
                        color: "primary",
                        show: false,
                    });
                });
        }
    };

    {
        /************************************  Deployment Waveform Tab fuctions **************************************************************/
    }

    return (
        <div>
            <Row>
                <Col>
                    <h2 className="margin-top-10">Waveforms</h2>
                </Col>
            </Row>
            <br></br>
            <Row>
                <Col className="col-6">
                    <label>Select ESA File:</label>
                    <br></br>
                    <Dropdown
                        options={esaList}
                        value={selectedEsa}
                        onChange={(value) => {
                            setSelectedEsa(value);
                        }}
                    />
                </Col>
                <Col>
                    <label>Enter length for moving average:</label>
                    <br></br>
                    <Col className="col-8 margin_left_-15 waveform-sec-ctn">
                        <Form.Control
                            type="number"
                            min={1}
                            max={1024}
                            value={movingAvgLength}
                            onInput={(newDuration) => onMovingAvgLengthChange(newDuration.target.value)}
                            isInvalid={isMovingAvgLengthInvalid}
                            required
                        />
                    </Col>
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    <Form.Check
                        onChange={(event) => {
                            setDisplayLLCurrent(event.target.checked);
                        }}
                        type="checkbox"
                        label="Display L-L Current"
                        checked={displayLLCurrent}
                    />
                </Col>
            </Row>
            <Row>
                <Col className="col-2">
                    {tabData.isPlotLoading ? (
                        <ReactLoading type="bars" color="#007bff" height={50} width={50} />
                    ) : (
                        <Button variant="primary" onClick={onPlotButtonClick} className="plot_button">
                            Plot
                        </Button>
                    )}
                </Col>
                <Col className="col-5 waveformAlert">
                    <Alert
                        variant={alert.color}
                        show={alert.show}
                        onClose={() => setAlert({ ...alert, show: false })}
                        dismissible
                    >
                        {alert.text}
                    </Alert>
                </Col>
            </Row>
        </div>
    );
}
