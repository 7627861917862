import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import DateTimePicker from "../../../../DatePickers/DateTimePicker/DateTimePicker";
import EventsAnalysisAPI from "../../../../../api/EventsAnalysisApi";
import Cookies from "js-cookie";
import ReactLoading from "react-loading";

const formatDateTime = (date) => {
    return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate() +
        " " +
        date.toTimeString().split(" ")[0]
    );
};

export default function Correlating({ tabData, setTabData, setPlotsOrder }) {
    const token = Cookies.get("Session");

    // extarcting from redux slice
    const { selectedCustomer, selectedFacility, selectedEquipment, equipmentTimezone } = useSelector(
        (state) => state.equipmentsPortfolio
    );
    // Correlating Event Tab Hooks

    const [isCorrelatingTableLoading, setIsCorrelatingTableLoading] = useState(false);

    const [correlatingAlert, setCorrelatingAlert] = useState({
        color: "blue",
        text: "",
        show: false,
    });

    const [correlatingEventsDateTime, setCorrelatingEventsDateTime] = useState(new Date());

    const [correlatingEventsSeconds, setCorrelatingEventsSeconds] = useState(2);
    const [isCorrelatingDurationInValid, setIsCorrelatingDurationInValid] = useState(false);

    useEffect(() => {
        setCorrelatingAlert({ ...correlatingAlert, show: false });
    }, [selectedCustomer, selectedFacility, selectedEquipment]);

    useEffect(() => {
        if (tabData.currentSelectedTimeOnEventsCaptureTab) {
            setCorrelatingEventsDateTime(tabData.currentSelectedTimeOnEventsCaptureTab);
        }
    }, [tabData.currentSelectedTimeOnEventsCaptureTab]);

    {
        /************************************ Correlating Tab fuctions **************************************************************/
    }

    const onCorrelatingDateTimeChange = (date) => {
        setCorrelatingEventsDateTime(date);
    };

    const onCorrelatingSecondsChange = (val) => {
        setCorrelatingEventsSeconds(val);
        if (val <= 0) {
            setIsCorrelatingDurationInValid(true);
        } else {
            setIsCorrelatingDurationInValid(false);
        }
    };
    const onCorrelatingPlotButtonClick = () => {
        // Hiding the banner
        setCorrelatingAlert({
            text: "No Events Found",
            show: false,
            color: "primary",
        });
        // Hiding the correlating Table
        setTabData((prevState) => {
            return {
                ...prevState,
                showTable: false,
            };
        });

        if (!selectedCustomer) {
            setCorrelatingAlert({
                color: "primary",
                show: true,
                text: "Select a Customer",
            });
        } else if (!selectedFacility) {
            setCorrelatingAlert({
                color: "primary",
                show: true,
                text: "Select a Facility",
            });
        } else {
            setIsCorrelatingTableLoading(true);
            setTabData((prevState) => {
                return {
                    ...prevState,
                    tabelData: { rows: [], columns: [] },
                    tableData: { columns: [], rows: [], metadata: {} },
                    tableMetaData: { selectedFacility: selectedFacility },
                    showTable: true,
                };
            });

            EventsAnalysisAPI.get_correlating_events_list(
                token,
                selectedFacility.facility_id,
                formatDateTime(correlatingEventsDateTime),
                correlatingEventsSeconds,
                equipmentTimezone.value
            )
                .then((response) => {
                    if (response.status === 204) {
                        setCorrelatingAlert({
                            text: "No Events Found",
                            show: true,
                            color: "primary",
                        });
                        setIsCorrelatingTableLoading(false);
                        setTabData((prevState) => {
                            return {
                                ...prevState,
                                showTable: false,
                                isCorrelatingTableLoading: false,
                            };
                        });
                    } else {
                        setPlotsOrder({
                            eventsCapture: 2,
                            correlatingEvents: 1,
                            eventsTrending: 3,
                            eventsThreshold: 4,
                            eventsFeaturesPlot: 5,
                            selEventsTrendingPlot: 7,
                        });
                        setTabData((prevState) => {
                            return {
                                ...prevState,
                                tabelData: { rows: [], columns: [] },
                                tableData: { columns: [], rows: [], metadata: {} },
                                tableMetaData: { selectedFacility: selectedFacility },
                                showTable: true,
                                isCorrelatingTableLoading: true,
                            };
                        });
                        setTabData((prevState) => {
                            return {
                                ...prevState,
                                tableData: response.data.content,
                                isCorrelatingTableLoading: false,
                                showTable: true,
                            };
                        });
                        setIsCorrelatingTableLoading(false);
                        setPlotsOrder({
                            eventsCapture: 2,
                            correlatingEvents: 1,
                            eventsTrending: 3,
                            eventsThreshold: 4,
                            eventsFeaturesPlot: 5,
                            selEventsTrendingPlot: 7,
                        });
                    }
                })
                .catch((err) => {
                    setIsCorrelatingTableLoading(false);
                    setTabData((prevState) => {
                        return {
                            ...prevState,
                            isCorrelatingTableLoading: false,
                        };
                    });
                    console.log(err);
                });
        }
    };

    return (
        <div>
            <Row>
                <Col>
                    <h2 className="margin-top-10">Correlating Events</h2>
                </Col>
            </Row>
            <Row>
                <Col className="col-5 event-data oneSec-date-input">
                    <label>Select Time:</label>
                    <br></br>
                    <DateTimePicker
                        selectedDate={correlatingEventsDateTime}
                        onDateTimeChange={onCorrelatingDateTimeChange}
                    />
                </Col>
                <Col className="col-3">
                    <label>Tolerance in seconds:</label>
                    <br></br>
                    <Form.Control
                        type="number"
                        min="0"
                        max="400000"
                        value={correlatingEventsSeconds}
                        onChange={(newDuration) => onCorrelatingSecondsChange(newDuration.target.value)}
                        isInvalid={isCorrelatingDurationInValid}
                    />
                </Col>
            </Row>

            <Row>
                <Col className="col-2">
                    <br></br>
                    {isCorrelatingTableLoading ? (
                        <ReactLoading type="bars" height={50} width={50} color="#007bff" />
                    ) : (
                        <Button variant="primary" onClick={onCorrelatingPlotButtonClick} className="plot_button">
                            Plot
                        </Button>
                    )}
                </Col>
                <Col className="col-5 eventsAlert">
                    <br />
                    <Alert
                        variant={correlatingAlert.color}
                        show={correlatingAlert.show}
                        onClose={() => setCorrelatingAlert({ ...correlatingAlert, show: false })}
                        dismissible
                    >
                        {correlatingAlert.text}
                    </Alert>
                </Col>
            </Row>
        </div>
    );
}
