import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import "./css/RealTimeWaveForm.css";
import Form from "react-bootstrap/Form";
import WaveformAnalysisAPI from "../../../../../api/WaveformApi";
import Cookies from "js-cookie";
import ReactLoading from "react-loading";

export default function RTWaveform({ tabData, setTabData, setPlotsOrder }) {
    const token = Cookies.get("Session");

    // extarcting from redux slice
    const { selectedCustomer, selectedFacility, selectedEquipment, equipmentTimezone } = useSelector(
        (state) => state.equipmentsPortfolio
    );
    const selectedVoltageType = useSelector((state) => state.voltageType.selectedVoltageType);

    // RealTime Waveform Plot Hooks
    const [realTimeWaveformAlert, setRealTimeWaveformAlert] = useState({
        color: "blue",
        text: "",
        show: false,
    });

    const [displayLLCurrent, setDisplayLLCurrent] = useState(false);

    useEffect(() => {
        setTabData((prevState) => {
            return {
                ...prevState,
                plotMetaData: {
                    ...prevState.plotMetaData,
                    displayLLCurrent: displayLLCurrent,
                },
            };
        });
    }, [displayLLCurrent]);

    const [movingAvgLength, setMovingAvgLength] = useState(1);
    const [isMovingAvgLengthInvalid, setIsMovingAvgLengthInvalid] = useState(false);
    {
        /************************************ RealTime Waveform Tab fuctions **************************************************************/
    }

    const onMovingAvgLengthChange = (newLength) => {
        //Add time delay for set / replot
        const length = parseFloat(newLength);
        setMovingAvgLength(length);
        if (length >= 1 && length <= 512) {
            setIsMovingAvgLengthInvalid(false);
        } else {
            setIsMovingAvgLengthInvalid(true);
        }
    };

    const onRealTimeWaveformPlotButtonClick = async () => {
        if (!selectedCustomer) {
            setRealTimeWaveformAlert({
                color: "primary",
                text: "Select a Customer",
                show: true,
            });
        } else if (!selectedFacility) {
            setRealTimeWaveformAlert({
                color: "primary",
                text: "Select a Facility",
                show: true,
            });
        } else if (!selectedEquipment) {
            setRealTimeWaveformAlert({
                color: "primary",
                text: "Select an Equipment",
                show: true,
            });
        } else if (isMovingAvgLengthInvalid) {
            setRealTimeWaveformAlert({
                color: "primary",
                text: "Please select value between 1 and 512",
                show: true,
            });
        } else {
            setRealTimeWaveformAlert({ ...realTimeWaveformAlert, show: false });
            setPlotsOrder({
                rtWaveform: 1,
                waveform: 2,
            });
            setTabData((prevState) => {
                return {
                    ...prevState,
                    plotData: {
                        Np_I_peak: { x: "", y: "" },
                        Time: [],
                        Ia: [],
                        Ib: [],
                        Ic: [],
                        Va: [],
                        Vb: [],
                        Vc: [],
                    },
                    tableData: { rows: "", columns: [] },
                    isPlotLoading: true,
                    plotMetaData: {
                        selectedEquipment: selectedEquipment,
                        selectedVoltageType: selectedVoltageType,
                        movingAvgLength: movingAvgLength,
                        equipmentTimezone: equipmentTimezone.value,
                        displayLLCurrent: displayLLCurrent,
                    },
                    showPlot: true,
                };
            });

            WaveformAnalysisAPI.getRTPlotData(token, selectedEquipment.value)
                .then((response) => {
                    if (response.status === 204) {
                        setTabData((prevState) => {
                            return {
                                ...prevState,
                                isPlotLoading: false,
                            };
                        });

                        setRealTimeWaveformAlert({
                            color: "primary",
                            text: "No Data Found",
                            show: true,
                        });
                    } else if (response.status === 200) {
                        setTabData((prevState) => {
                            return {
                                ...prevState,
                                plotData: response.data.content.plot_data,
                                tableData: response.data.content.table_data,
                                showPlot: true,
                                isPlotLoading: false,
                            };
                        });
                        setPlotsOrder({
                            rtWaveform: 1,
                            waveform: 2,
                        });
                    }
                })
                .catch((error) => {
                    setTabData((prevState) => {
                        return {
                            ...prevState,
                            isPlotLoading: false,
                        };
                    });
                    console.log(error);
                });
        }
    };

    return (
        <div>
            <Row>
                <Col>
                    <h2 className="margin-top-10">Real-time Waveforms</h2>
                </Col>
            </Row>
            <br></br>
            <Row>
                <Col>
                    <label>Enter length for moving average:</label>
                    <br></br>
                    <Col className="col-8 margin_left_-15 waveform-sec-ctn">
                        <Form.Control
                            type="number"
                            min={1}
                            max={512}
                            value={movingAvgLength}
                            onInput={(newDuration) => onMovingAvgLengthChange(newDuration.target.value)}
                            isInvalid={isMovingAvgLengthInvalid}
                            required
                        />
                    </Col>
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    <Form.Check
                        onChange={(event) => {
                            setDisplayLLCurrent(event.target.checked);
                        }}
                        type="checkbox"
                        label="Display L-L Curent"
                        checked={displayLLCurrent}
                    />
                </Col>
            </Row>
            <br></br>

            <Row>
                <Col className="col-2">
                    {tabData.isPlotLoading ? (
                        <ReactLoading type="bars" color="#007bff" height={50} width={50} />
                    ) : (
                        <Button variant="primary" onClick={onRealTimeWaveformPlotButtonClick} className="plot_button">
                            Plot
                        </Button>
                    )}
                </Col>
                <Col className="col-5 waveformAlert">
                    <Alert
                        variant={realTimeWaveformAlert.color}
                        show={realTimeWaveformAlert.show}
                        onClose={() =>
                            setRealTimeWaveformAlert({
                                ...realTimeWaveformAlert,
                                show: false,
                            })
                        }
                        dismissible
                    >
                        {realTimeWaveformAlert.text}
                    </Alert>
                </Col>
            </Row>
        </div>
    );
}
