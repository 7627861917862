import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import Alert from "react-bootstrap/Alert";
import DateRangePicker from "../../../../DatePickers/DateRangePicker/DateRangePicker";
import EventsAnalysisAPI from "../../../../../api/EventsAnalysisApi";
import Dropdown from "react-dropdown";
import Cookies from "js-cookie";
import ReactLoading from "react-loading";

const eventsFeaturesOptions = [
    { label: "pqd_imax", value: "pqd_imax" },
    { label: "pqd_imin", value: "pqd_imin" },
    { label: "pqd_vmax", value: "pqd_vmax" },
    { label: "pqd_vmin", value: "pqd_vmin" },
    { label: "i_rms", value: "i_rms" },
    { label: "v_rms", value: "v_rms" },
    { label: "secs_abv_np", value: "secs_abv_np" },
    { label: "area_abv_np_peak", value: "area_abv_np_peak" },
    { label: "v_peak", value: "v_peak" },
    { label: "i_peak", value: "i_peak" },
    { label: "max_v_drop", value: "max_v_drop" },
    { label: "pq_delta_i", value: "pq_delta_i" },
    { label: "pq_delta_v", value: "pq_delta_v" },
    { label: "i_freq_min", value: "i_freq_min" },
    { label: "pqd_zmin", value: "pqd_zmin" },
    { label: "pqd_zmax", value: "pqd_zmax" },
    { label: "max_v_snar", value: "max_v_snar" },
    { label: "i_single_phasing", value: "i_single_phasing" },
];

const formatDate = (date) => {
    return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
};

const formatDateTime = (date) => {
    return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate() +
        " " +
        date.toTimeString().split(" ")[0]
    );
};

export default function EventsFeatures({ tabData, setTabData, setPlotsOrder }) {
    const token = Cookies.get("Session");

    // extarcting from redux slice
    const { selectedCustomer, selectedFacility, selectedEquipment, equipmentTimezone } = useSelector(
        (state) => state.equipmentsPortfolio
    );

    // Events Features Tab Hooks
    const [eventsFeaturesStartDate, setEventsFeaturesStartDate] = useState(
        new Date(new Date().setMonth(new Date().getMonth() - 1))
    );
    const [eventsFeatureEndDate, setEventsFeaturesEndDate] = useState(new Date());

    const [isEventsFeaturesPlotLoading, setIsEventsFeaturesPlotLoading] = useState(false);

    const [eventsFeaturesAlert, setEventsFeaturesAlert] = useState({
        color: "blue",
        text: "",
        show: false,
    });

    const [selectedEventsFeatures, setSelectedEventsFeatures] = useState();

    useEffect(() => {
        if (selectedEquipment) {
            const equipStartDate = new Date(parseInt(selectedEquipment.equipment_start_epoch * 1000));

            // Reset Date Pickers
            const tempDate = new Date(new Date().setMonth(new Date().getMonth() - 1));

            if (equipStartDate > eventsFeaturesStartDate) {
                if (equipStartDate > tempDate) setEventsFeaturesStartDate(equipStartDate);
                else setEventsFeaturesStartDate(tempDate);

                setEventsFeaturesEndDate(new Date());
            }
        }
    }, [selectedEquipment]);

    {
        /************************************ Events Feature Tab fuctions **************************************************************/
    }

    const onEventsFeaturesStartDateChange = (date) => {
        setEventsFeaturesStartDate(date);
    };

    const onEventsFeaturesEndDateChange = (date) => {
        setEventsFeaturesEndDate(date);
    };

    const onEventsFeaturesPlotButtonClick = () => {
        if (!selectedCustomer) {
            setEventsFeaturesAlert({
                color: "primary",
                show: true,
                text: "Select a Customer",
            });
        } else if (!selectedFacility) {
            setEventsFeaturesAlert({
                color: "primary",
                show: true,
                text: "Select a Facility",
            });
        } else if (!selectedEquipment) {
            setEventsFeaturesAlert({
                color: "primary",
                show: true,
                text: "Select an Equipment ",
            });
        } else if (!selectedEventsFeatures) {
            setEventsFeaturesAlert({
                color: "primary",
                show: true,
                text: "Select a Event Feature",
            });
        } else {
            setEventsFeaturesAlert({ ...eventsFeaturesAlert, show: false });
            setIsEventsFeaturesPlotLoading(true);
            setPlotsOrder({
                eventsCapture: 4,
                correlatingEvents: 2,
                eventsTrending: 3,
                eventsThreshold: 5,
                eventsFeaturesPlot: 1,
            });
            setTabData((prevState) => {
                return {
                    ...prevState,
                    plotData: {
                        Title: [],
                        current_fall: {
                            hover_data: [],
                            x: [],
                            y: [],
                        },
                        current_rise: {
                            hover_data: [],
                            x: [],
                            y: [],
                        },
                        start_events: {
                            hover_data: [],
                            x: [],
                            y: [],
                        },
                        start_d_events: {
                            hover_data: [],
                            x: [],
                            y: [],
                        },
                        stop_events: {
                            hover_data: [],
                            x: {},
                            y: [],
                        },
                        stop_u_events: {
                            hover_data: [],
                            x: {},
                            y: [],
                        },
                        voltage_fall: {
                            hover_data: [],
                            x: [],
                            y: [],
                        },
                        voltage_rise: {
                            hover_data: [],
                            x: [],
                            y: [],
                        },
                        y_axis_title: [],
                    },
                    plotMetaData: {
                        selectedEquipment: selectedEquipment,
                        startDate: formatDate(eventsFeaturesStartDate),
                        endDate: formatDate(eventsFeatureEndDate),
                    },
                    showPlot: true,
                    isPlotLoading: true,
                };
            });

            EventsAnalysisAPI.get_events_features_data(
                token,
                selectedEquipment.value,
                selectedEventsFeatures.value,
                formatDate(eventsFeaturesStartDate),
                formatDate(eventsFeatureEndDate),
                equipmentTimezone.value
            )
                .then((response) => {
                    setIsEventsFeaturesPlotLoading(false);
                    if (response.status === 200) {
                        setTabData((prevState) => {
                            return {
                                ...prevState,
                                plotData: response.data.content.plot_data,
                                showPlot: true,
                                isPlotLoading: false,
                            };
                        });
                        setPlotsOrder({
                            eventsCapture: 4,
                            correlatingEvents: 2,
                            eventsTrending: 3,
                            eventsThreshold: 5,
                            eventsFeaturesPlot: 1,
                        });
                    }
                })
                .catch((err) => {
                    setTabData((prevState) => {
                        return {
                            ...prevState,
                            isPlotLoading: false,
                        };
                    });
                    setIsEventsFeaturesPlotLoading(false);
                    console.log(err);
                });
        }
    };

    return (
        <div>
            <Row>
                <Col className="col-7 event-data">
                    <h2 className="margin-top-10">Events Features</h2>
                    <label>Select Date Range:</label>
                    <br></br>
                    <DateRangePicker
                        startDate={eventsFeaturesStartDate}
                        endDate={eventsFeatureEndDate}
                        onStartDateChange={onEventsFeaturesStartDateChange}
                        onEndDateChange={onEventsFeaturesEndDateChange}
                    />
                </Col>
            </Row>
            <Row>
                <Col className="col-6">
                    <br />
                    <p>Select Events Feature:</p>

                    <Dropdown
                        options={eventsFeaturesOptions}
                        value={selectedEventsFeatures}
                        onChange={(value) => {
                            setSelectedEventsFeatures(value);
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col className="col-2">
                    <br></br>
                    {isEventsFeaturesPlotLoading ? (
                        <ReactLoading type="bars" height={50} width={50} color="#007bff" />
                    ) : (
                        <Button variant="primary" onClick={onEventsFeaturesPlotButtonClick} className="plot_button">
                            Plot
                        </Button>
                    )}
                </Col>
                <Col className="col-5 eventsAlert">
                    <br />
                    <Alert
                        variant={eventsFeaturesAlert.color}
                        show={eventsFeaturesAlert.show}
                        onClose={() =>
                            setEventsFeaturesAlert({
                                ...eventsFeaturesAlert,
                                show: false,
                            })
                        }
                        dismissible
                    >
                        {eventsFeaturesAlert.text}
                    </Alert>
                </Col>
            </Row>
        </div>
    );
}
