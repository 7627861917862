import React, { Component } from 'react';
import './css/ErrorBoundaries.css';

export default class ErrorBoundaries extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hasError: false,
		};
	}

	static getDerivedStateFromError(error) {
		return {
			hasError: true,
		};
	}
	componentDidCatch(error, info) {
		console.log(error);
	}
	render() {
		if (this.state.hasError) {
			return (
				<div className='top-margin'>
					<div className='content-align'>
						<h3>Something went wrong...</h3>
					</div>
					<div className='content-align'>
						<p>
							This issue has been reported to the Volta Team. Please reload the page to continue using
							Volta Analytics
						</p>
					</div>
				</div>
			);
		}
		return this.props.children;
	}
}
