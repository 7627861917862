import React from 'react';
import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import { equipmentsPortfolioActions } from '../../../../store/equipmentsPortfolioSlice/equipmentsPortfolioSlice';

const SelectFacility = () => {
	const { selectedCustomer, facilityList, selectedFacility, portfolio } = useSelector(
		(state) => state.equipmentsPortfolio
	);
	const dispatch = useDispatch();

	const onSelectFacility = (facility) => {
		dispatch(equipmentsPortfolioActions.setSelectedFacility(facility));
	};

	return (
		<React.Fragment>
			<Select options={facilityList} value={selectedFacility} onChange={(value) => onSelectFacility(value)} />
		</React.Fragment>
	);
};

export default SelectFacility;
