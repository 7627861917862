import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Alert from 'react-bootstrap/Alert';
import { useSelector, useDispatch } from 'react-redux';
import { voltageTypeActions } from '../../../../store/volatgeTypeSlice/volatgeTypeSlice';

import './css/VoltageTypeSelect.css';

const voltageTypeOptions = [
	{ label: 'Line to Line', value: 'L-L' },
	{ label: 'Line to Neutral', value: 'L-N' },
];

const VoltageTypeSelect = () => {
	const { selectedVoltageType } = useSelector((state) => state.voltageType);
	const selectedEquipment = useSelector((state) => state.equipmentsPortfolio.selectedEquipment);
	const dispatch = useDispatch();

	const [isDropdownDisabled, setIsDropdownDisabled] = useState(false);
	const [alert, setAlert] = useState({
		text: 'Feature not available for this Equipment',
		color: 'primary',
		show: false,
	});
	const [dropdownMem, setdropdownMem] = useState(false);

	useEffect(() => {
		if (selectedEquipment) {
			if (selectedEquipment.product_type === 'SEL' ||
				selectedEquipment.eq_type_sub === 'v1' ||
				selectedEquipment.eq_type === 'dc') {
				//sees if the current dropdown selection needs to change
				if (selectedVoltageType.value !== 'L-L') {
					//dropdownMem flags to change selection back once unlocked
					setdropdownMem(false);
					dispatch(voltageTypeActions.setVoltageType({ label: 'Line to Line', value: 'L-L' }));
				//dropdownMem flags to keep selection once dropdown is unlocked
				} else {setdropdownMem(true);}
				setIsDropdownDisabled(true);
			} 
			else {
				//Checks if dropdown was peviously disabled
				if(isDropdownDisabled){
					//changes the dropdown according to the flag.
					if(dropdownMem){
						if(selectedVoltageType.value != 'L-L')dispatch(voltageTypeActions.setVoltageType({ label: 'Line to Line', value: 'L-L' }));
					}
					else{
						if(selectedVoltageType.value != 'L-N')dispatch(voltageTypeActions.setVoltageType({ label: 'Line to Neutral', value: 'L-N' }));
					}
				}
				setIsDropdownDisabled(false);
			}
		}
		else {
			//Checks if dropdown was peviously disabled
			if(isDropdownDisabled){
				//changes the dropdown according to the flag.
				if(dropdownMem){
					dispatch(voltageTypeActions.setVoltageType({ label: 'Line to Line', value: 'L-L' }));
				}
				else{
					dispatch(voltageTypeActions.setVoltageType({ label: 'Line to Neutral', value: 'L-N' }));
				}
			}
			setIsDropdownDisabled(false);
			
		}
	}, [selectedEquipment]);

	return (
		<React.Fragment>
			<div
				onClick={() => {
					// if (isDropdownDisabled) {
					// 	setAlert({ ...alert, show: true });
					// 	setTimeout(() => {
					// 		setAlert({ ...alert, show: false });
					// 	}, 4000);
					// }
				}}>
				<Select
					isDisabled={isDropdownDisabled}
					options={voltageTypeOptions}
					value={selectedVoltageType}
					onChange={(val) => {
						dispatch(voltageTypeActions.setVoltageType(val));
					}}
				/>
			</div>
			<br></br>
			<div className='dropdown-alert'>
				<Alert variant={alert.color} show={alert.show}>
					{alert.text}
				</Alert>
			</div>
		</React.Fragment>
	);
};

export default VoltageTypeSelect;
