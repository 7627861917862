import authAxios from "../axios/authAxios";

class WaveformAnalysisAPI {
    static getEsaList(accessToken, location_node_id, date, timezone, productType) {
        return authAxios.get("/v1/common/get_esa_list/", {
            params: {
                location_node_id: `${location_node_id}`,
                start_date: `${date}`,
                end_date: `${date}`,
                timezone: `${timezone}`,
                product_type: `${productType}`,
            },
            headers: { Authorization: `Bearer ${accessToken}` },
        });
    }

    static getEsaPlotData(accessToken, selectedEsa_value, location_node_id, duration, productType) {
        return authAxios.get("/v1/waveform_analysis/esa_plot/", {
            params: {
                event_key: `${selectedEsa_value}`,
                location_node_id: `${location_node_id}`,
                plot_duration: `${duration}`,
                product_type: `${productType}`,
            },
            headers: { Authorization: `Bearer ${accessToken}` },
        });
    }

    static getRTPlotData(accessToken, location_node_id) {
        return authAxios.get("/v1/waveform_analysis/get_rt_waveform_data/", {
            params: {
                location_node_id: `${location_node_id}`,
            },
            headers: { Authorization: `Bearer ${accessToken}` },
        });
    }

    static getNodeESAList(accessToken, location_node_id, date, timezone) {
        return authAxios.get("/v1/common/get_node_esa_list/", {
            params: {
                location_node_id: location_node_id,
                esa_date: date,
                timezone: timezone,
            },
            headers: { Authorization: `Bearer ${accessToken}` },
        });
    }

    static getDeploymentWaveform(accessToken, location_node_id, timezone) {
        return authAxios.get("/v1/common/get_init_esa_list/", {
            params: {
                location_node_id: location_node_id,
                timezone: timezone,
            },
            headers: { Authorization: `Bearer ${accessToken}` },
        });
    }
}

export default WaveformAnalysisAPI;
