import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import DateRangePicker from "../../../../DatePickers/DateRangePicker/DateRangePicker";
import EventsAnalysisAPI from "../../../../../api/EventsAnalysisApi";
import Cookies from "js-cookie";
import ReactLoading from "react-loading";

const formatDate = (date) => {
    return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
};

export default function EventsTrending({ tabData, setTabData, setPlotsOrder }) {
    const token = Cookies.get("Session");

    // extarcting from redux slice
    const { selectedCustomer, selectedFacility, selectedEquipment, equipmentTimezone } = useSelector(
        (state) => state.equipmentsPortfolio
    );

    // Events Trending Tab Hooks
    const [eventsTrendingStartDate, setEventsTrendingStartDate] = useState(
        new Date(new Date().setMonth(new Date().getMonth() - 1))
    );
    const [eventsTrendingEndDate, setEventsTrendingEndDate] = useState(new Date());

    const [isEventsTrendingPlotLoading, setIsEventsTrendingPlotLoading] = useState(false);
    const [eventsTrendingAlert, setEventsTrendingAlert] = useState({
        color: "blue",
        text: "",
        show: false,
    });

    useEffect(() => {
        setEventsTrendingAlert({ ...eventsTrendingAlert, show: false });
    }, [selectedCustomer, selectedFacility, selectedEquipment]);

    useEffect(() => {
        if (selectedEquipment) {
            const equipStartDate = new Date(parseInt(selectedEquipment.equipment_start_epoch * 1000));

            const tempDate = new Date(new Date().setMonth(new Date().getMonth() - 1));
            if (equipStartDate > eventsTrendingStartDate) {
                if (equipStartDate > tempDate) setEventsTrendingStartDate(equipStartDate);
                else setEventsTrendingStartDate(tempDate);

                setEventsTrendingEndDate(new Date());
            }
        }
    }, [selectedEquipment]);

    {
        /************************************ Events Trending Tab fuctions **************************************************************/
    }

    const onEventsTrendingStartDateChange = (date) => {
        setEventsTrendingStartDate(date);
    };

    const onEventsTrendingEndDateChange = (date) => {
        setEventsTrendingEndDate(date);
    };

    const onEventsTrendingPlotButtonClick = () => {
        if (!selectedCustomer) {
            setEventsTrendingAlert({
                color: "primary",
                show: true,
                text: "Select a Customer",
            });
        } else if (!selectedFacility) {
            setEventsTrendingAlert({
                color: "primary",
                show: true,
                text: "Select a Facility",
            });
        } else if (!selectedEquipment) {
            setEventsTrendingAlert({
                color: "primary",
                show: true,
                text: "Select an Equipment ",
            });
        } else {
            setEventsTrendingAlert({ ...eventsTrendingAlert, show: false });
            setIsEventsTrendingPlotLoading(true);
            setTabData((prevState) => {
                return {
                    ...prevState,
                    plotMetaData: {
                        selectedEquipment: selectedEquipment,
                        startDate: formatDate(eventsTrendingStartDate),
                        endDate: formatDate(eventsTrendingEndDate),
                    },
                };
            });

            if (selectedEquipment.product_type === "SEL") {
                setPlotsOrder({
                    eventsCapture: 3,
                    correlatingEvents: 2,
                    eventsTrending: 6,
                    eventsThreshold: 4,
                    eventsFeaturesPlot: 5,
                    selEventsTrendingPlot: 1,
                });
                setTabData((prevState) => {
                    return {
                        ...prevState,
                        plotMetaData: {
                            selectedEquipment: selectedEquipment,
                            startDate: formatDate(eventsTrendingStartDate),
                            endDate: formatDate(eventsTrendingEndDate),
                        },
                        selPlotData: {
                            alarms_trips_trending: {
                                day: [],
                                Total_Alarms: [],
                                Total_Trips: [],
                            },
                            tickers: [],
                        },
                        isEventsTrendingPlotLoadingForSelPlot: true,
                        showSELPlot: true,
                    };
                });

                EventsAnalysisAPI.getSELAlarmsTrip(
                    token,
                    selectedEquipment.value,
                    formatDate(eventsTrendingStartDate),
                    formatDate(eventsTrendingEndDate),
                    equipmentTimezone.value
                )
                    .then((response) => {
                        if (response.status === 200) {
                            setTabData((prevState) => {
                                return {
                                    ...prevState,
                                    selPlotData: response.data.content.plot_data,
                                    showSELPlot: true,
                                    isEventsTrendingPlotLoadingForSelPlot: false,
                                };
                            });
                        } else if (response.status === 204) {
                            setEventsTrendingAlert({
                                color: "primary",
                                show: true,
                                text: "No Data Found",
                            });
                            setTabData((prevState) => {
                                return {
                                    ...prevState,
                                    isEventsTrendingPlotLoadingForSelPlot: false,
                                };
                            });
                        }
                        setPlotsOrder({
                            eventsCapture: 3,
                            correlatingEvents: 2,
                            eventsTrending: 6,
                            eventsThreshold: 4,
                            eventsFeaturesPlot: 5,
                            selEventsTrendingPlot: 1,
                        });
                        setIsEventsTrendingPlotLoading(false);
                        setTabData((prevState) => {
                            return {
                                ...prevState,
                                isEventsTrendingPlotLoadingForSelPlot: false,
                            };
                        });
                    })
                    .catch((err) => {
                        setIsEventsTrendingPlotLoading(false);
                        console.log(err);
                    });
            } else {
                setTabData((prevState) => {
                    return {
                        ...prevState,
                        plotMetaData: {
                            selectedEquipment: selectedEquipment,
                            startDate: formatDate(eventsTrendingStartDate),
                            endDate: formatDate(eventsTrendingEndDate),
                        },
                        isEventsTrendingPlotLoading: true,
                        showPlot: true,
                    };
                });
                setPlotsOrder({
                    eventsCapture: 3,
                    correlatingEvents: 2,
                    eventsTrending: 6,
                    eventsThreshold: 4,
                    eventsFeaturesPlot: 5,
                    selEventsTrendingPlot: 1,
                });
                setTabData((prevState) => {
                    return {
                        ...prevState,
                        showPlot: true,
                        isEventsTrendingPlotLoading: true,
                    };
                });

                EventsAnalysisAPI.get_events_trending_data(
                    token,
                    selectedEquipment.value,
                    formatDate(eventsTrendingStartDate),
                    formatDate(eventsTrendingEndDate),
                    equipmentTimezone.value
                )
                    .then((response) => {
                        if (response.status === 200) {
                            setTabData((prevState) => {
                                return {
                                    ...prevState,
                                    plotData: response.data.content.plot_data.events_trending,
                                    showPlot: true,
                                    isEventsTrendingPlotLoading: false,
                                };
                            });
                            setIsEventsTrendingPlotLoading(false);
                        } else if (response.status === 204) {
                            setEventsTrendingAlert({
                                color: "primary",
                                show: true,
                                text: "No Data Found",
                            });
                            setIsEventsTrendingPlotLoading(false);
                        } else {
                            setIsEventsTrendingPlotLoading(false);
                        }
                    })
                    .catch((err) => {
                        setTabData((prevState) => {
                            return {
                                ...prevState,
                                isEventsTrendingPlotLoading: false,
                            };
                        });
                        setIsEventsTrendingPlotLoading(false);
                        console.log(err);
                    });
            }
        }
    };

    return (
        <div>
            <Row>
                <Col>
                    <h2 className="margin-top-10">Events Trending</h2>
                </Col>
            </Row>
            <Row>
                <Col className="col-7 event-data">
                    <label>Select Date Range:</label>
                    <br></br>
                    <DateRangePicker
                        startDate={eventsTrendingStartDate}
                        endDate={eventsTrendingEndDate}
                        onStartDateChange={onEventsTrendingStartDateChange}
                        onEndDateChange={onEventsTrendingEndDateChange}
                    />
                </Col>
            </Row>
            <Row>
                <Col className="col-2">
                    <br></br>
                    {isEventsTrendingPlotLoading ? (
                        <ReactLoading type="bars" height={50} width={50} color="#007bff" />
                    ) : (
                        <Button variant="primary" onClick={onEventsTrendingPlotButtonClick} className="plot_button">
                            Plot
                        </Button>
                    )}
                </Col>
                <Col className="col-5 eventsAlert">
                    <br />
                    <Alert
                        variant={eventsTrendingAlert.color}
                        show={eventsTrendingAlert.show}
                        onClose={() =>
                            setEventsTrendingAlert({
                                ...eventsTrendingAlert,
                                show: false,
                            })
                        }
                        dismissible
                    >
                        {eventsTrendingAlert.text}
                    </Alert>
                </Col>
            </Row>
        </div>
    );
}
