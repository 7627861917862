import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import arrow from './up-arrow.png';
  
const ScrollButton = () =>{

  
  const [visible, setVisible] = useState(false)
  const [visible2, setVisible2] = useState(true)
  
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 20){
      setVisible(true)
      setVisible2(false)
    } 
    else if (scrolled <= 20){
      setVisible(false)
      setVisible2(true)
    }
  };


  
  const scrollToTop = () =>{
    if(visible)
      window.scrollTo({
        top: 0, 
        behavior: 'smooth'
      });
    if(visible2)
    window.scrollTo({ 
      top: document.body.scrollHeight, 
      behavior: 'smooth'
    });
  };

  
  window.addEventListener('scroll', toggleVisible);

  const topStyle = {
    display:'inline',
    position: 'fixed',
    right: '40px',
    bottom: '40px',
    borderRadius: '50px'
  };
  return (
    <Button
    onClick={scrollToTop} 
    style={topStyle}>
      <img src={arrow} alt='Top' style={{transform: visible ? 'rotate(0deg)' : 'rotate(180deg)'}} />
    </Button>
  );
}
  
export default ScrollButton;