import React from 'react';
import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import { equipmentsPortfolioActions } from '../../../../store/equipmentsPortfolioSlice/equipmentsPortfolioSlice';

const timezoneOptions = [
	{ label: 'Equipment Time Zone', value: 'equipment_timezone' },
	{ label: '(UTC) Coordinated Universal Time ', value: 'UTC' },
];

const TimezoneSelect = () => {
    const { equipmentTimezone } = useSelector((state) => state.equipmentsPortfolio);
	const dispatch = useDispatch();

	const onTimezoneChange = (timezone) => {
		dispatch(equipmentsPortfolioActions.setEqipmentTimezone(timezone));
	};

	return <Select options={timezoneOptions} value={equipmentTimezone} onChange={onTimezoneChange} />;
};

export default TimezoneSelect;
