import React from 'react';
import Button from 'react-bootstrap/Button';
import Cookies from 'js-cookie';

export default function LogoutButton() {
	const onLogout = () => {
		Cookies.remove('Session');
		window.location = process.env.REACT_APP_PORTAL_URL + '#/logout';
	};

	return (
		<Button variant='secondary' onClick={onLogout}>
			Logout
		</Button>
	);
}
