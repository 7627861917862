import React from 'react';
import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import { equipmentsPortfolioActions } from '../../../../store/equipmentsPortfolioSlice/equipmentsPortfolioSlice';
import TrendingAnalysisAPI from '../../../../api/TrendingAnalysisApi';
import Cookies from 'js-cookie';

const SelectEquipment = () => {
	const { equipmentsList, selectedEquipment } = useSelector((state) => state.equipmentsPortfolio);

	const portalToken = Cookies.get('portalSession');

	const dispatch = useDispatch();

	const onSelectEquipment = (equipment) => {
		// Clear Selected list
		dispatch(equipmentsPortfolioActions.setSelectedEquipment(equipment));
		
		// check node connectivity status
		if (equipment.product_type === 'Node') {
			TrendingAnalysisAPI.getPortalRealTimeMetering(portalToken, equipment.location_node_id.split('.')[0])
				.then((response) => {
					dispatch(equipmentsPortfolioActions.setEquipmentConnStatus(response.data.data.connectedStatus));
				})
				.catch((err) => console.log(err));
		};
	}

	return (
		<React.Fragment>
			<Select options={equipmentsList} value={selectedEquipment} onChange={(value) => onSelectEquipment(value)} />
		</React.Fragment>
	);
};

export default SelectEquipment;
