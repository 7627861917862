import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Cookies from 'js-cookie';
import "./css/RealTimeMetring.css"
import ReactLoading from 'react-loading';

import TrendingAnalysisAPI from '../../../../../api/TrendingAnalysisApi';

export default function RealTimeMeteringTab({ setTabData, setPlotOrder }) {
	const portalToken = Cookies.get('portalSession');

	// extarcting from redux slice
	const { selectedCustomer, selectedFacility, selectedEquipment } = useSelector(
		(state) => state.equipmentsPortfolio
	);

	const [realTimeAlert, setRealTimeAlert] = useState({
		color: '',
		show: false,
		text: '',
	});
	const [isPlotLoading, setIsPlotLoading] = useState(false);

	const onPlotButtonClick = () => {
		setRealTimeAlert({
			color: 'primary',
			show: false,
			text: '',
		});
		if (!selectedCustomer) {
			setRealTimeAlert({
				color: 'primary',
				show: true,
				text: 'Select a Customer',
			});
		} else if (!selectedFacility) {
			setRealTimeAlert({
				color: 'primary',
				show: true,
				text: 'Select a Facility',
			});
		} else if (!selectedEquipment) {
			setRealTimeAlert({
				color: 'primary',
				show: true,
				text: 'Select an Equipment ',
			});
		} else {
			clearInterval(window.realTimeMeteringTimeId)
			setIsPlotLoading(true)
			setTabData((prevState) => {
				return {
					...prevState,
					plotMetaData: {
						selectedEquipment: selectedEquipment,
					},
					plotData:{
						connectedStatus: "",
						current: 0,
						current1: 0.,
						current2: 0,
						current3: 0,
						displayEquipmentType: "",
						displayTimestamp: "",
						equipmentType: "",
						facilityName: "",
						facilityPortfolio: null,
						fieldCurrent: null,
						fieldVoltage: null,
						hp: 0,
						iNoise: 0,
						isCalibrated:null,
						lineFrequency: 0,
						locationId: "",
						meterString: null,
						meteringStatus: "",
						name: "",
						power: 0,
						powerDailyAverage: null,
						powerDailyAverageKw: null,
						powerDailyPeak: null,
						powerDailyPeakKw: null,
						powerFactor: 0,
						powerFactor1: 0,
						powerFactor2: 0,
						powerFactor3: 0,
						powerKw: 0,
						powerTodayAverage: null,
						powerTodayAverageKw: null,
						powerTodayPeak: null,
						powerTodayPeakKw: null,
						powerdailyAverage: null,
						powerdailyPeak: null,
						runningTime: 0,
						runningTimeDays: 0,
						secondarySerialNumber: null,
						serialNumber: "",
						siteName: "",
						status: "",
						timestamp: 0,
						timezone: "",
						type: "",
						vNoise: 0,
						voltage: 0,
						voltage1: 0,
						voltage2: 0,
						voltage3: 0,
						voltageAB: 0,
						voltageBC: 0,
						voltageCA: 0,
					},
					isPlotLoading: true,
					showPlot: true,
				};
			});
			setPlotOrder({
				fifteenMinutes: 2,
				oneSecond: 3,
				realTimeMetering: 1,
			});

			window.realTimeMeteringTimeId = setInterval(() => {
				TrendingAnalysisAPI.getPortalRealTimeMetering(
					portalToken,
					selectedEquipment.location_node_id.split('.')[0]
				).then((response) => {
					if (response.status === 200) {
						if (response.data.data.status === 'OFFLINE') {
							clearInterval(window.realTimeMeteringTimeId)
							setIsPlotLoading(false)
							setRealTimeAlert({
								color: 'primary',
								show: true,
								text: 'Equipment is Not Cloud Connected',
							});

							setTabData((prevState) => {
								return {
									...prevState,
									showPlot: false,
									isPlotLoading:false
								};
							});
						}
						if (response.data.data.displayTimestamp !== null) {
							setTabData((prevState) => {
								return {
									...prevState,
									showPlot: true,
									plotData: response.data.data,
									isPlotLoading:false
								};
							});
						}
					}
					setIsPlotLoading(false)
				}
				).catch(error => {
					clearInterval(window.realTimeMeteringTimeId)
					setTabData((prevState) => {
						return {
							...prevState,
							isPlotLoading:false
						};
					});
					setIsPlotLoading(false)
					console.log(error)
				});
			}, 2000);
		}
	};

	return (
		<div>
			<Row>
				<Col>
					<h2 className='margin-top-10'>Real-Time Metering</h2>{' '}
				</Col>
			</Row>

			<Row>
				<Col className='col-2'>
					{isPlotLoading ? (
						<ReactLoading type='bars' height={50} width={50} color='#007bff' />
					) : (
						<Button variant='primary' onClick={onPlotButtonClick} className='plot_button'>
							Plot
						</Button>
					)}
				</Col>
				<Col className='col-6 trendingAlert'>
					<Alert
						variant={realTimeAlert.color}
						show={realTimeAlert.show}
						onClose={() => setRealTimeAlert({ ...realTimeAlert, show: false })}
						dismissible>
						{realTimeAlert.text}
					</Alert>
				</Col>
			</Row>
		</div>
	);
}
