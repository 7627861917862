import React, { useState, Suspense, lazy, useEffect } from "react";
import { useSelector } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Card from "react-bootstrap/Card";
import LeftFilterBox from "../../LeftFilterBox/LeftFilterBox";
import EventsCapture from "./components/EventsCapture/EventsCapture";
import Correlating from "./components/Correlating/Correlating";
import EventsThreshold from "./components/EventsThreshold/EventsThreshold";
import EventsTrending from "./components/EventsTrending/EventsTrending";
import EventsFeatures from "./components/EventsFeature/EventsFeatures";
import EventsSortingTable from "../../EventsSortingTable/EventsSortingTable";

const EventsCapturePlot = lazy(() => import("../../plots/EventsCapturePlot"));
const CorrelatingEventsPlot = lazy(() => import("../../plots/CorrelatingEventsPlot"));
const EventsTrendingPlot = lazy(() => import("../../plots/EventsTrendingPlot"));
const EventsThresholdPlot = lazy(() => import("../../plots/EventsThresholdPlot"));
const EventsFeaturesPlots = lazy(() => import("../../plots/EventsFeaturesPlots"));
const SELTrendingPlot = lazy(() => import("../../plots/SELTrendingPlot"));

export default function EventsAnalysisTab() {
    // extarcting from redux slice
    const { selectedEquipment, showSELMenu } = useSelector((state) => state.equipmentsPortfolio);
    const [activeTabKey, setActiveTabKey] = useState("Events Capture");

    const [eventsCaptureTabData, setEventsCaptureTabData] = useState({
        eventsTableData: { rows: [], columns: [] },
        selectedEventFile: [],
        plotData: [],
        showPlotOnDoubleClick: false,
        plotMetaData: "",
        showPlot: false,
        isEventsCapturePlotLoading: false,
        currentSelectedTimeOnEventsCaptureTab: 0,
    });

    const [CorrelatingTabData, setCorrelatingTabData] = useState({
        tabelData: { rows: [], columns: [] },
        plotMetaData: "",
        showPlot: false,
        isCorrelatingPlotLoading: false,
        currentSelectedTimeOnEventsCaptureTab: 0,
    });

    const [eventsTrendingTabData, setEventsTrendingTabData] = useState({
        plotData: [],
        selPlotData: [],
        plotMetaData: "",
        showPlot: false,
        showSELPlot: false,
        isEventsTrendingPlotLoading: false,
        isEventsTrendingPlotLoadingForSelPlot: false,
    });

    const [eventsThresholdTabData, setEventsThresholdTabData] = useState({
        plotData: [],
        plotMetaData: "",
        showPlot: false,
        isPlotLoading: false,
    });

    const [eventsFeaturesTabData, setEventsFeaturesTabData] = useState({
        plotData: {
            current_fall: { x: [], y: [], hover_data: [] },
            current_rise: { x: [], y: [], hover_data: [] },
            voltage_fall: { x: [], y: [], hover_data: [] },
            voltage_rise: { x: [], y: [], hover_data: [] },
            start_events: { x: [], y: [], hover_data: [] },
            stop_events: { x: [], y: [], hover_data: [] },
            y_axis_title: [],
            Title: [],
        },
        plotMetaData: "",
        showPlot: false,
        isPlotLoading: false,
    });

    const [plotsOrder, setPlotsOrder] = useState({
        eventsCapture: 1,
        correlating: 2,
        eventsTrending: 3,
        SELEventsTrending: 4,
        eventsThreshold: 5,
        eventsFeatures: 6,
        selEventsTrendingPlot: 7,
    });

    useEffect(() => {
        if (showSELMenu && activeTabKey !== "Events Capture" && activeTabKey !== "Events Trending") {
            setActiveTabKey("Events Capture");
        }
    }, [showSELMenu]);

    useEffect(() => {
        setCorrelatingTabData((prevData) => {
            return {
                ...prevData,
                currentSelectedTimeOnEventsCaptureTab: eventsCaptureTabData.currentSelectedTimeOnEventsCaptureTab,
            };
        });
    }, [eventsCaptureTabData.currentSelectedTimeOnEventsCaptureTab]);

    return (
        <div>
            <Card>
                <Card.Body>
                    <Row className="mt-3">
                        {/************************************ Facility and Node Selection Column **************************************************************/}
                        <Col className="col-4">
                            <LeftFilterBox />
                        </Col>

                        {/********************************************************* Sub Menu Tabs ******************************************************************/}
                        <Col className="col-8 ">
                            <Card>
                                <Card.Body>
                                    <Tabs
                                        defaultActiveKey="Events Capture"
                                        activeKey={activeTabKey}
                                        variant="pills"
                                        onSelect={(tabName) => {
                                            setActiveTabKey(tabName);
                                        }}
                                    >
                                        {/******************************************** Events Capture Tab ********************************************************************/}
                                        <Tab eventKey="Events Capture" title="Events Capture">
                                            <EventsCapture
                                                tabData={eventsCaptureTabData}
                                                setTabData={setEventsCaptureTabData}
                                                setPlotsOrder={setPlotsOrder}
                                            />
                                        </Tab>
                                        {/******************************************** Correlating Tab ********************************************************************/}
                                        <Tab
                                            eventKey="Correlating Events"
                                            title="Correlating Events"
                                            tabClassName={showSELMenu ? "d-none" : ""}
                                        >
                                            <Correlating
                                                tabData={CorrelatingTabData}
                                                setTabData={setCorrelatingTabData}
                                                setPlotsOrder={setPlotsOrder}
                                            />
                                        </Tab>

                                        {/******************************************** Events Trending Tab *****************************************************************/}
                                        <Tab eventKey="Events Trending" title="Events Trending">
                                            <EventsTrending
                                                tabData={eventsTrendingTabData}
                                                setTabData={setEventsTrendingTabData}
                                                setPlotsOrder={setPlotsOrder}
                                            />
                                        </Tab>

                                        {/******************************************** Events Threshold Tab *****************************************************************/}
                                        <Tab
                                            eventKey="Events Threshold"
                                            title="Events Threshold"
                                            tabClassName={showSELMenu ? "d-none" : ""}
                                        >
                                            <EventsThreshold
                                                tabData={eventsThresholdTabData}
                                                setTabData={setEventsThresholdTabData}
                                                setPlotsOrder={setPlotsOrder}
                                            />
                                        </Tab>
                                        {/******************************************** Events Features Tab *****************************************************************/}
                                        <Tab
                                            eventKey="Events Features"
                                            title="Events Features"
                                            tabClassName={showSELMenu ? "d-none" : ""}
                                        >
                                            <EventsFeatures
                                                tabData={eventsFeaturesTabData}
                                                setTabData={setEventsFeaturesTabData}
                                                setPlotsOrder={setPlotsOrder}
                                            />
                                        </Tab>
                                    </Tabs>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <br></br>
            <Suspense fallback={<div>Loading..</div>}>
                <EventsSortingTable
                    rows={eventsCaptureTabData.eventsTableData.rows}
                    columns={eventsCaptureTabData.eventsTableData.columns}
                    setTabData={setEventsCaptureTabData}
                    tabData={eventsCaptureTabData}
                    selectedEquipment={selectedEquipment}
                />
                <br></br>
            </Suspense>
            <Suspense fallback={<div>Loading..</div>}>
                <Row>
                    <Col lg={{ order: plotsOrder.eventsCapture, span: 12 }}>
                        {eventsCaptureTabData.showPlot ? (
                            <EventsCapturePlot tabData={eventsCaptureTabData} setTabData={setEventsCaptureTabData} />
                        ) : null}
                    </Col>

                    <Col lg={{ order: plotsOrder.correlatingEvents, span: 12 }}>
                        {CorrelatingTabData.showTable ? (
                            <CorrelatingEventsPlot tabData={CorrelatingTabData} setTabData={setCorrelatingTabData} />
                        ) : null}
                    </Col>

                    <Col lg={{ order: plotsOrder.eventsTrending, span: 12 }}>
                        {eventsTrendingTabData.showPlot ? (
                            <EventsTrendingPlot tabData={eventsTrendingTabData} setTabData={setEventsTrendingTabData} />
                        ) : null}
                    </Col>

                    <Col lg={{ order: plotsOrder.eventsThreshold, span: 12 }}>
                        {eventsThresholdTabData.showPlot ? (
                            <EventsThresholdPlot
                                tabData={eventsThresholdTabData}
                                setTabData={setEventsThresholdTabData}
                            />
                        ) : null}
                    </Col>
                    <Col lg={{ order: plotsOrder.eventsFeaturesPlot, span: 12 }}>
                        {eventsFeaturesTabData.showPlot ? (
                            <EventsFeaturesPlots
                                tabData={eventsFeaturesTabData}
                                setTabData={setEventsFeaturesTabData}
                            />
                        ) : null}
                    </Col>

                    <Col lg={{ order: plotsOrder.selEventsTrendingPlot, span: 12 }}>
                        {eventsTrendingTabData.showSELPlot ? (
                            <SELTrendingPlot tabData={eventsTrendingTabData} setTabData={setEventsTrendingTabData} />
                        ) : null}
                    </Col>
                </Row>
            </Suspense>
        </div>
    );
}
