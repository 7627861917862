import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useSelector } from 'react-redux';
import './EquipmentConnStatus.css';

const EquipmentConnStatus = () => {
	const equipmentConnStatus = useSelector((state) => state.equipmentsPortfolio.equipmentConnStatus);


	return (
		<React.Fragment>
					{equipmentConnStatus === 'OFFLINE' ? (
						<>
							<br></br>
							<Row className='justify-content-md-center'>
								<Col className='col-11 fault-alert-box'>
									<text>Selected Equipment is Not Cloud Connected</text>
								</Col>
							</Row>
						</>
					) : null}

		</React.Fragment>
	);
};

export default EquipmentConnStatus;
