import React, { useState, Suspense, lazy, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import LeftFilterBox from '../../LeftFilterBox/LeftFilterBox';

import FifteenMinutes from './components/FifteenMinutesTab/FifteenMinutes';
import OneSecondTrendingTab from './components/OneSecondTab/oneSecond';
import RealTimeMeteringTab from './components/RealTimeMeteringTab/RealTimeMetring';
import OneHourTab from './components/OneHourTab/OneHourTab';
import AccumulatedTab from './components/AccumulatedTab/AccumulatedTab';
import { tabStatusActions } from '../../../store/tabStatusSlice/tabStatusSlice';
import AccumulatedTrendingPlot from '../../plots/AccumulatedTrendingPlot';
import RelayTrendingTab from './components/RelayTrendingTab/RelayTrending';
const MinutesTrendingPlot15 = lazy(() => import('../../plots/15MinutesTrendingPlot'));
const OneSecondNewTrendingPlot = lazy(() => import('../../plots/OneSecondNewTrendingPlot'));
const RealTimeMeteringPlot = lazy(() => import('../../plots/RealTimeMeteringPlot'));
const OneHourTrendingPlot = lazy(() => import('../../plots/OneHourTrendingPlot'))
const RelayTrendingPlot = lazy(() => import('../../plots/RelayTrendingPlot'))

export default function TrendingAnalysisTab() {
	const dispatch = useDispatch()
	const showSELMenu = useSelector((state) => state.equipmentsPortfolio.showSELMenu);

	const {product_type, selectedEquipment} = useSelector((state) => state.equipmentsPortfolio);

	const [activeTabKey, setActiveTabKey] = useState('15 minute')

	useEffect(() =>{
		if(product_type == 'GERelay'){
			setActiveTabKey('Trending')
		}else if(product_type == 'SEL'){
			setActiveTabKey("One Hour Trending");
		}else{
			setActiveTabKey("15 minute");
		}
	},[product_type])

	const [plotsOrder, setPlotsOrder] = useState({
		fifteenMinutes: 1,
		oneSecond: 2,
		realTimeMetering: 3,
		accumulated: 4,
	});

	const [fifteenMinutesTabData, setFifteenMinutesTabData] = useState({
		plotData: {},
		plotMetaData: '',
		showPlot: false,
		isPlotLoading: false,
	});

	const [oneSecondTabData, setOneSecondTabData] = useState({
		plotData: '',
		plotMetaData: '',
		showPlot: false,
		isPlotLoading: false,
	});

	const [realTimeMeteringTabData, setRealTimeMeteringTabData] = useState({
		plotData: {
			voltage1: [],
			Vb: [],
			Vc: [],
			Ia: [],
			Ib: [],
			Ic: [],
			Time: []
		},
		plotMetaData: '',
		showPlot: false,
		isPlotLoading: false,
	});

	const [oneHourTabData, setOneHourTabData] = useState({
		plotData: { data: '', layout: '' },
		plotMetaData: '',
		tableData: { data: '', columns: '' },
		showPlot: false,
		isPlotLoading: false,
	});

	const [accumulatedTabData, setAccumulatedTabData] = useState({
		plotData: {},
		plotMetaData: '',
		showPlot: false,
		isPlotLoading: false,
	});

	const [relayTrendingTabData, setRelayTrendingTabData] = useState({
		plotData: { data: "", layout: "" },
		plotMetaData: "",
		tableData: { data: "", columns: "" },
		showPlot: false,
		isPlotLoading: false,
	});


	return (
        <div>
            <Card>
                <Card.Body>
                    <Row className="mt-3">
                        {/************************************ Facility and Node Selection Column **************************************************************/}
                        <Col className="col-4">
                            <LeftFilterBox />
                        </Col>
                        {/************************************************* Sub Menu Tabs **********************************************************************/}
                        <Col className="col-8">
                            <Card>
                                <Card.Body>
                                    <Tabs
                                        activeKey={activeTabKey}
                                        variant="pills"
                                        onSelect={(tabName) => {
                                            dispatch(
                                                tabStatusActions.setSelectedTab(
                                                    tabName
                                                )
                                            );
                                            setActiveTabKey(tabName);
                                        }}
                                    >
                                        {/* **************************************** 15 minute Tabs **************************************** */}
                                        <Tab
                                            eventKey="15 minute"
                                            title="15 Minute"
                                            tabClassName={
                                                product_type == "Node"
                                                    ? ""
                                                    : "d-none"
                                            }
                                        >
                                            <FifteenMinutes
                                                tabData={fifteenMinutesTabData}
                                                setTabData={
                                                    setFifteenMinutesTabData
                                                }
                                                setPlotOrder={setPlotsOrder}
                                            />
                                        </Tab>

                                        {/* **************************************** 1 second New Tab **************************************** */}
                                        <Tab
                                            eventKey="1 second new"
                                            title="1 Second"
                                            tabClassName={
                                                product_type == "Node"
                                                    ? ""
                                                    : "d-none"
                                            }
                                        >
                                            <OneSecondTrendingTab
                                                tabData={oneSecondTabData}
                                                setTabData={setOneSecondTabData}
                                                setPlotOrder={setPlotsOrder}
                                            />
                                        </Tab>
                                        {/* **************************************** Real-Time Metering Tab **************************************** */}
                                        <Tab
                                            eventKey="Real-Time Metering"
                                            title="Real-Time Metering"
                                            tabClassName={
                                                product_type == "Node"
                                                    ? ""
                                                    : "d-none"
                                            }
                                        >
                                            <RealTimeMeteringTab
                                                tabData={
                                                    realTimeMeteringTabData
                                                }
                                                setTabData={
                                                    setRealTimeMeteringTabData
                                                }
                                                setPlotOrder={setPlotsOrder}
                                            />
                                        </Tab>
                                        {/* **************************************** One Hour Tending **************************************** */}
                                        <Tab
                                            eventKey="One Hour Trending"
                                            title="One Hour Trending"
                                            tabClassName={
                                                product_type == "SEL"
                                                    ? ""
                                                    : "d-none"
                                            }
                                        >
                                            <OneHourTab
                                                tabData={oneHourTabData}
                                                setTabData={setOneHourTabData}
                                                setPlotOrder={setPlotsOrder}
                                            />
                                        </Tab>
                                        {/* **************************************** Accumulated Tab **************************************** */}
                                        <Tab
                                            eventKey="Accumulated"
                                            title="Accumulated"
                                            tabClassName={
                                                product_type == "Node"
                                                    ? ""
                                                    : "d-none"
                                            }
                                        >
                                            <AccumulatedTab
                                                tabData={accumulatedTabData}
                                                setTabData={
                                                    setAccumulatedTabData
                                                }
                                                setPlotOrder={setPlotsOrder}
                                            />
                                        </Tab>
                                        {/* **************************************** GE Relay Trending **************************************** */}
                                        <Tab
                                            eventKey="Trending"
                                            title="Trending"
                                            tabClassName={
                                                product_type == "GERelay"
                                                    ? ""
                                                    : "d-none"
                                            }
                                        >
                                            <RelayTrendingTab
                                                tabData={relayTrendingTabData}
                                                setTabData={
                                                    setRelayTrendingTabData
                                                }
                                                setPlotOrder={setPlotsOrder}
                                            />
                                        </Tab>
                                    </Tabs>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <br></br>

            {/********************************************** Plot Section ***************************************************************************/}
            <Suspense fallback={<div>Loading..</div>}>
                <Row>
                    <Col lg={{ order: plotsOrder.fifteenMinutes, span: 12 }}>
                        {fifteenMinutesTabData.showPlot ? (
                            <MinutesTrendingPlot15
                                setTabData={setFifteenMinutesTabData}
                                tabData={fifteenMinutesTabData}
                            />
                        ) : null}
                    </Col>
                    <Col lg={{ order: plotsOrder.oneSecond, span: 12 }}>
                        {oneSecondTabData.showPlot ? (
                            <OneSecondNewTrendingPlot
                                tabData={oneSecondTabData}
                                setTabData={setOneSecondTabData}
                            />
                        ) : null}
                    </Col>

                    <Col lg={{ order: plotsOrder.realTimeMetering, span: 12 }}>
                        {realTimeMeteringTabData.showPlot ? (
                            <RealTimeMeteringPlot
                                tabData={realTimeMeteringTabData}
                                setTabData={setRealTimeMeteringTabData}
                            />
                        ) : null}
                    </Col>
                    <Col lg={{ order: 0, span: 12 }}>
                        {oneHourTabData.showPlot ? (
                            <OneHourTrendingPlot
                                tabData={oneHourTabData}
                                setTabData={setOneHourTabData}
                            />
                        ) : null}
                    </Col>
                    <Col lg={{ order: 0, span: 12 }}>
                        {accumulatedTabData.showPlot ? (
                            <AccumulatedTrendingPlot
                                tabData={accumulatedTabData}
                                setTabData={setAccumulatedTabData}
                            />
                        ) : null}
                    </Col>
                    <Col lg={{ order: 0, span: 12 }}>
                        {relayTrendingTabData.showPlot ? (
                            <RelayTrendingPlot
                                tabData={relayTrendingTabData}
                                setTabData={setRelayTrendingTabData}
                            />
                        ) : null}
                    </Col>
                </Row>
            </Suspense>
        </div>
    );
}
