import React, { useState, Suspense, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Card from "react-bootstrap/Card";

import LeftFilterBox from "../../LeftFilterBox/LeftFilterBox";
import WaveformAnalysis from "./components/Waveform/Waveform";
import RTWaveform from "./components/RTWaveform/RTWavefrom";
import DeploymentWaveform from "./components/Deployment/Deployment";
import { tabStatusActions } from "../../../store/tabStatusSlice/tabStatusSlice";
import { useDispatch, useSelector } from "react-redux";
const WaveformAnalysisPlot = React.lazy(() => import("../../plots/WaveformAnalysisPlot"));
const RealTimeWaveformPlot = React.lazy(() => import("../../plots/RealTimeWaveformPlot"));

export default function WaveformAnalysisTab() {
    const dispatch = useDispatch();

    const showSELMenu = useSelector((state) => state.equipmentsPortfolio.showSELMenu);

    const [plotsOrder, setPlotsOrder] = useState({
        waveform: 1,
        rtWaveform: 2,
        deployment: 3,
    });

    const [waveformTabData, setWaveformTabData] = useState({
        plotData: "",
        plotMetaData: "",
        showPlot: false,
        isPlotLoading: false,
    });

    const [rtWaveformTabData, setRTWaveformTabData] = useState({
        plotData: {
            Time: [],
            Ia: [],
            Ib: [],
            Ic: [],
            Va: [],
            Vb: [],
            Vc: [],
            Np_I_peak: { x: "", y: "" },
        },
        tableData: { rows: "", columns: [""] },
        plotMetaData: "",
        showPlot: false,
        isPlotLoading: false,
    });

    const [deploymentTabData, setDeploymentTabData] = useState({
        plotData: "",
        plotMetaData: "",
        showPlot: false,
        isPlotLoading: false,
    });

    const [activeTabKey, setActiveTabKey] = useState("Waveforms");

    useEffect(() => {
        if (showSELMenu && activeTabKey === "RealTime Waveforms") {
            setActiveTabKey("Waveforms");
        }
    }, [showSELMenu]);

    return (
        <div>
            <Card>
                <Card.Body>
                    <Row className="mt-3">
                        {/************************************ Facility and Node Selection Column **************************************************************/}
                        <Col className="col-4">
                            <LeftFilterBox />
                        </Col>
                        {/************************************************* Sub Menu Tabs **********************************************************************/}
                        <Col className="col-8">
                            <Card>
                                <Card.Body>
                                    <Tabs
                                        defaultActiveKey="Waveforms"
                                        activeKey={activeTabKey}
                                        id="wavefom-tabs"
                                        variant="pills"
                                        onSelect={(tabName) => {
                                            dispatch(tabStatusActions.setSelectedTab(tabName));
                                            setActiveTabKey(tabName);
                                        }}
                                        mountOnEnter={true}
                                        unmountOnExit={true}
                                    >
                                        {/* **************************************** WaveForm Tabs **************************************** */}
                                        <Tab eventKey="Waveforms" title="Waveforms">
                                            <WaveformAnalysis
                                                tabData={waveformTabData}
                                                setTabData={setWaveformTabData}
                                                setPlotsOrder={setPlotsOrder}
                                            />
                                        </Tab>
                                        {/* **************************************** RealTime WaveForm Tabs **************************************** */}
                                        <Tab
                                            eventKey="RealTime Waveforms"
                                            title="Real-time Waveforms"
                                            tabClassName={showSELMenu ? "d-none" : ""}
                                        >
                                            <RTWaveform
                                                tabData={rtWaveformTabData}
                                                setTabData={setRTWaveformTabData}
                                                setPlotsOrder={setPlotsOrder}
                                            />
                                        </Tab>
                                        {/* **************************************** Deployment WaveForm Tabs **************************************** */}
                                        <Tab
                                            eventKey="Deployment Waveforms"
                                            title="Deployment Waveforms"
                                            tabClassName={showSELMenu ? "d-none" : ""}
                                        >
                                            <DeploymentWaveform
                                                tabData={deploymentTabData}
                                                setTabData={setDeploymentTabData}
                                                setPlotsOrder={setPlotsOrder}
                                            />
                                        </Tab>
                                    </Tabs>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <br></br>
            {/********************************************** Plot Section ***************************************************************************/}

            <Suspense fallback={<div>Loading..</div>}>
                <Row>
                    <Col lg={{ order: plotsOrder.waveform, span: 12 }}>
                        <WaveformAnalysisPlot tabData={waveformTabData} setTabData={setWaveformTabData} />
                    </Col>
                    <Col lg={{ order: plotsOrder.rtWaveform, span: 12 }}>
                        <RealTimeWaveformPlot tabData={rtWaveformTabData} setTabData={setRTWaveformTabData} />
                    </Col>
                    <Col lg={{ order: plotsOrder.deployment, span: 12 }}>
                        <WaveformAnalysisPlot tabData={deploymentTabData} setTabData={setDeploymentTabData} />
                    </Col>
                </Row>
            </Suspense>
        </div>
    );
}
