import { configureStore } from '@reduxjs/toolkit'
import equipmentsPortfolioSlice from './equipmentsPortfolioSlice/equipmentsPortfolioSlice';
import voltageTypeSlice from './volatgeTypeSlice/volatgeTypeSlice';
import tabStatusSlice from './tabStatusSlice/tabStatusSlice';

const store = configureStore({
  reducer: {
    equipmentsPortfolio: equipmentsPortfolioSlice.reducer,
    voltageType: voltageTypeSlice.reducer,
    tabStatus: tabStatusSlice.reducer
  },
})

export default store;