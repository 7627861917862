import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useSelector } from 'react-redux';
import './DeploymentIssueBanner.css';

const DeploymentIssueBanner = () => {
	const selectedEquipment = useSelector((state) => state.equipmentsPortfolio.selectedEquipment);

	return (
		<React.Fragment>
			{selectedEquipment ? (
				<>
					{selectedEquipment.deployment_issue ? (
						<>
							<br></br>
							<Row className='justify-content-md-center'>
								<Col className='col-11 deployment-issue-box'>
									<text>Selected Node has a Deployment Issue</text>
								</Col>
							</Row>
						</>
					) : null}
				</>
			) : null}
		</React.Fragment>
	);
};

export default DeploymentIssueBanner;
