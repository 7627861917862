import React from 'react';
import logo from './volta_insite_logo_2024.png';
import LogoutButton from './components/LogoutButton/LogoutButton';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import VoltageTypeSelect from './components/VolatgeTypeSelect/VoltageTypeSelect';
import TimezoneSelect from './components/TimezoneSelect/TimezoneSelect';

import './TopNav.css';

export default function TopNav() {
	return (
		<React.Fragment>
			<Row className='align-self-center  my-auto '>
				<Col className='col-6'>
					<img src={logo} alt='logo' />
				</Col>
				<Col className='col-1 mt-4 voltageLabel'>Voltage:</Col>
				<Col className='col-2 mt-4 l-lDropdown'>
					<VoltageTypeSelect />
				</Col>
				<Col className='timezone-ctn col-2 timezoneDropdown'>
					<TimezoneSelect />
				</Col>

				<Col className='logoutbutton-ctn col-1'>
					{' '}
					<LogoutButton />{' '}
				</Col>
			</Row>
		</React.Fragment>
	);
}
