import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useSelector } from 'react-redux';
import './FaultAlertBanner.css';

const FaultAlertBanner = () => {
	const selectedEquipment = useSelector((state) => state.equipmentsPortfolio.selectedEquipment);

	return (
		<React.Fragment>
			{selectedEquipment ? (
				<>
					{selectedEquipment.fault_alert_scans ? (
						<>
							<br></br>
							<Row className='justify-content-md-center'>
								<Col className='col-11 fault-alert-box'>
									<text>Selected Equipment has Paused Analytics Notifications</text>
								</Col>
							</Row>
						</>
					) : null}
				</>
			) : null}
		</React.Fragment>
	);
};

export default FaultAlertBanner;
